import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';

import { ProviderEnum, SocialSignInProviderEnum } from '@dynamic-labs/sdk-api';
import { Alert, OpacityAnimation, Typography } from '@dynamic-labs/northstar';

import styles from '../../../BrandedWalletsSection/MultiWallet/MultiWallet.module.scss';
import { useProvidersContext } from '../../../../../../../../app/context/ProvidersContext';
import { useSignInWithSocialProviders } from '../../../../../../../../app/routes/Configurations/Providers/hooks/useSignInWithSocialProviders';

import { SocialUseForSignUpToggle } from './SocialUseForSignUpToggle';
import { CredentialsToUseRadios } from './CredentialsToUseRadios';
import { SocialUseForLinkingToggle } from './SocialUseForLinkingToggle';

type SocialDrawerContentProps = {
  provider: ProviderEnum;
};

export const SocialDrawerContent: FC<SocialDrawerContentProps> = ({
  provider,
}) => {
  const { t } = useTranslation();

  const { getProviderValue } = useProvidersContext();
  const { enabledProviders } = useSignInWithSocialProviders({
    disabled: false,
  });

  const isFarcaster = provider === ProviderEnum.Farcaster;
  const isInstagram = provider === ProviderEnum.Instagram;
  const isTelegram = provider === ProviderEnum.Telegram;

  const shouldRenderCredentialsFields = !isFarcaster;

  // For signup we need to force the provider to be a SocialSignInProviderEnum
  const providerAsSignInProvider =
    provider as unknown as SocialSignInProviderEnum;

  const isProviderLinkingEnabled = Boolean(
    getProviderValue(provider, 'enabledAt'),
  );
  const isProviderSignUpEnabled = Boolean(
    enabledProviders.includes(provider as unknown as SocialSignInProviderEnum),
  );

  const isProviderEnabled = isProviderLinkingEnabled || isProviderSignUpEnabled;

  return (
    <>
      <div className={styles.section}>
        <Typography variant='paragraph-3' weight='medium' color='gray-1'>
          {t('v2.page.log_in_methods.social.drawer.authentication.title')}
        </Typography>

        <AnimatePresence>
          {!isProviderEnabled && (
            <OpacityAnimation>
              <Typography variant='paragraph-1' className={styles.warning}>
                {t(
                  'v2.page.log_in_methods.social.drawer.authentication.warning',
                )}
              </Typography>
            </OpacityAnimation>
          )}
        </AnimatePresence>

        {!isInstagram && (
          <SocialUseForSignUpToggle provider={providerAsSignInProvider} />
        )}

        <SocialUseForLinkingToggle provider={provider} />
      </div>

      {shouldRenderCredentialsFields && (
        <div className={styles.section}>
          <Typography variant='paragraph-3' weight='medium' color='gray-1'>
            {t('v2.page.log_in_methods.social.drawer.credentials_set_up.title')}
          </Typography>

          <CredentialsToUseRadios provider={provider} />
        </div>
      )}

      {isTelegram && (
        <Alert
          title={t(
            'v2.page.log_in_methods.social.drawer.form.telegram.autoWalletFFFormTitle',
          )}
          description={t(
            'v2.page.log_in_methods.social.drawer.form.telegram.autoWalletFFFormDescription',
          )}
          variant='warning'
          action={{
            onClick: () => {
              window.open(
                'https://dynamic.xyz/slack',
                '_blank',
                'noopener,noreferrer',
              );
            },
            text: t(
              'v2.page.log_in_methods.social.drawer.form.telegram.autoWalletFFFormCTA',
            ),
          }}
          show
        />
      )}
    </>
  );
};
