import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isEqual from 'react-fast-compare';
import { useMutation } from 'react-query';

import { EnvironmentEnum, Provider, ProviderEnum } from '@dynamic-labs/sdk-api';
import { Button, TrashIcon } from '@dynamic-labs/northstar';
import { EcdsaValidatorOptions } from '@dynamic-labs/sdk-api-core';

import { useSdkVersion } from '../../../../hooks/useSdkVersion';
import { getNetworkIcon } from '../../../../utils/getNetworkIcon';
import { environmentsApi } from '../../../../services/api';
import { useSettingsHasChanged } from '../../../../hooks/useSettingsHasChanged';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { useSettingsContext } from '../../../../context/SettingsContext';
import { RadioButton } from '../../../../components/RadioButton';
import { isProviderEnabled } from '../../../../utils/isProviderEnabled';
import { ErrorInfo } from '../../../../components/ErrorInfo';
import { ConfirmToast } from '../../../../components/ConfirmToast';
import { useSaveProvidersMutation } from '../../../../hooks/useSaveProvidersMutation';
import { useEnvironmentProviders } from '../../../../hooks/useEnvironmentProviders';
import {
  AccountAbstractionCircularIcon,
  AlchemyIcon,
  ZeroDevIcon,
} from '../../../../../icons';
import { EnvironmentTabsLayout } from '../../../../layouts/EnvironmentTabsLayout';
import { SideModalHeader } from '../../../../components/SideModalHeader';
import { Toggle } from '../../../../components/Toggle';
import { Typography } from '../../../../components/Typography';
import Input from '../../../../components/Input';
import Portal from '../../../../components/Portal';
import { useEnvironmentId } from '../../Providers/hooks';
import { didProviderChange } from '../utils/didProviderChange';
import { getProviderInProvidersResponse } from '../../utils';

import { ProviderControlTemplate } from './components';

const USERS_SETTING = 'users';
const WALLETS_SETTING = 'wallets';
const AA_UI_SETTING = 'aa_ui';

const MIN_MULTICHAIN_AA_VERSION = '3.3.0';

type AccountAbstractionModalProps = {
  onClickClose: () => void;
};
export const AccountAbstractionModal: FC<AccountAbstractionModalProps> = ({
  onClickClose,
}) => {
  const { isOutdatedSdkVersion, currentEnvSdkVersion } = useSdkVersion(
    MIN_MULTICHAIN_AA_VERSION,
  );
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { t } = useTranslation();
  const environmentId = useEnvironmentId();
  const {
    enableAlchemyAccountAbstractionIntegration,
    enableMultichainAccountAbstraction,
  } = useFlags();

  const { data: providersData } = useEnvironmentProviders(environmentId);

  const { activeEnvironmentType } = useEnvironmentsContext();

  const { setSettings, settings, initialSettings } = useSettingsContext();

  const {
    cancelChanges: cancelProjectChanges,
    updateInitialSettings: updateProjectInitialSettings,
  } = useSettingsHasChanged('sdk');

  const canEnableAccountAbstractionProviders = useMemo(() => {
    if (!providersData) return false;

    const { providers } = providersData;

    if (!providers) return false;

    return (
      isProviderEnabled(providers, ProviderEnum.MagicLink) ||
      isProviderEnabled(providers, ProviderEnum.Turnkey) ||
      isProviderEnabled(providers, ProviderEnum.CoinbaseWaas)
    );
  }, [providersData]);

  const savedAlchemyProvider = useMemo(
    () => getProviderInProvidersResponse(providersData, ProviderEnum.Alchemy),
    [providersData],
  );

  const savedZeroDevProvider = useMemo(
    () => getProviderInProvidersResponse(providersData, ProviderEnum.Zerodev),
    [providersData],
  );

  const [alchemyProvider, setAlchemyProvider] =
    useState<Provider>(savedAlchemyProvider);

  const [zeroDevProvider, setZeroDevProvider] =
    useState<Provider>(savedZeroDevProvider);

  const initialZeroDevProvider = useMemo(() => {
    const updatedProvider = { ...savedZeroDevProvider };

    if (
      updatedProvider.clientId &&
      !updatedProvider.multichainAccountAbstractionProviders?.length
    ) {
      updatedProvider.multichainAccountAbstractionProviders = [
        {
          chain: '',
          clientId: updatedProvider.clientId,
        },
      ];
    }

    setZeroDevProvider(updatedProvider);
    return updatedProvider;
  }, [savedZeroDevProvider]);

  const initialAlchemyProvider = useMemo(() => {
    const updatedProvider = { ...savedAlchemyProvider };

    setAlchemyProvider(updatedProvider);
    return updatedProvider;
  }, [savedAlchemyProvider]);

  const onClickCancelChanges = () => {
    setErrorMessage(undefined);
    setHoveredIndex(null);
    setZeroDevProvider(initialZeroDevProvider);
    setAlchemyProvider(initialAlchemyProvider);
    cancelProjectChanges();
  };

  /**
   * Reset the state when the environmentId changes
   */
  useEffect(() => {
    onClickCancelChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentId]);

  const didAlchemyProviderChanged = didProviderChange(
    alchemyProvider,
    initialAlchemyProvider,
  );

  const didZeroDevProviderChanged = didProviderChange(
    zeroDevProvider,
    initialZeroDevProvider,
  );

  const settingsHasChanged = !isEqual(
    initialSettings[activeEnvironmentType].sdk.accountAbstraction,
    settings[activeEnvironmentType].sdk.accountAbstraction,
  );

  const hasChanges =
    didAlchemyProviderChanged ||
    didZeroDevProviderChanged ||
    settingsHasChanged;

  const hasMultipleProvidersEnabled = Boolean(
    alchemyProvider?.enabledAt && zeroDevProvider?.enabledAt,
  );

  const { mutate: saveProviders, isLoading: isSavingProviders } =
    useSaveProvidersMutation();

  const { mutate: saveProjectSettings, isLoading: isSavingProjectSettings } =
    useMutation(
      async () =>
        environmentId &&
        environmentsApi.updateProjectSettings({
          environmentId,
          projectSettings: settings[activeEnvironmentType],
        }),
      {
        onSuccess: () => {
          updateProjectInitialSettings(
            activeEnvironmentType as EnvironmentEnum,
          );
        },
      },
    );

  const isLoading = isSavingProviders || isSavingProjectSettings;

  const onClickSave = () => {
    if (hasMultipleProvidersEnabled) return;
    setHoveredIndex(null);
    if (
      zeroDevProvider?.multichainAccountAbstractionProviders &&
      zeroDevProvider.multichainAccountAbstractionProviders.length > 1 &&
      isOutdatedSdkVersion
    ) {
      setErrorMessage(
        t(
          'integrations.wallets.account_abstraction.zero_dev.warnings.multichain_sdk_version',
          {
            currentSdkVersion: currentEnvSdkVersion,
            minimumSdkVersion: MIN_MULTICHAIN_AA_VERSION,
          },
        ),
      );
    } else {
      setErrorMessage(undefined);
    }

    saveProviders([alchemyProvider, zeroDevProvider]);
    saveProjectSettings();
  };

  const handleOnChangePrimaryClientId = (
    setState: Dispatch<SetStateAction<Provider>>,
    event: ChangeEvent<HTMLInputElement>,
  ) =>
    setState((prev) => ({
      ...prev,
      clientId: event.target.value,
    }));

  const handleOnChangeSecondaryClientIds = (
    setState: Dispatch<SetStateAction<Provider>>,
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) =>
    setState((prev) => ({
      ...prev,
      multichainAccountAbstractionProviders: [
        ...(prev.multichainAccountAbstractionProviders || []).slice(0, index),
        {
          ...(prev.multichainAccountAbstractionProviders?.[index] || {
            chain: '',
          }),
          clientId: event.target.value,
        },
        ...(prev.multichainAccountAbstractionProviders || []).slice(index + 1),
      ],
    }));

  const getSavedProviderNetwork = useCallback(
    (clientId?: string): string | undefined => {
      if (!savedZeroDevProvider) return undefined;

      const matchingProvider =
        savedZeroDevProvider.multichainAccountAbstractionProviders?.find(
          (provider) => provider.clientId === clientId,
        );

      if (matchingProvider) return matchingProvider.chain;

      return undefined;
    },
    [savedZeroDevProvider],
  );

  const handleOnToggleEnabledAt = (
    setState: Dispatch<SetStateAction<Provider>>,
  ) =>
    setState((prev) => ({
      ...prev,
      enabledAt: prev?.enabledAt
        ? null
        : savedAlchemyProvider?.enabledAt || new Date(),
    }));

  const handleAddingMultichainProvider = () => {
    setZeroDevProvider((prev) => ({
      ...prev,
      multichainAccountAbstractionProviders: [
        ...(prev.multichainAccountAbstractionProviders || []),
        { chain: '', clientId: '' },
      ],
    }));
  };

  const shouldEnableAddProviderButton = useMemo(() => {
    const providers =
      zeroDevProvider.multichainAccountAbstractionProviders || [];

    const lastProvider = providers[providers.length - 1];

    return Boolean(
      (savedZeroDevProvider?.ecdsaProviderType !==
        EcdsaValidatorOptions.SignerToEcdsa &&
        lastProvider?.chain &&
        lastProvider.chain !== '') ||
        lastProvider?.clientId === zeroDevProvider.clientId,
    );
  }, [
    savedZeroDevProvider?.ecdsaProviderType,
    zeroDevProvider.multichainAccountAbstractionProviders,
  ]);

  const handleAASetting = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedSetting = {};

    if (event.target.name === USERS_SETTING) {
      updatedSetting = {
        allUsers: event.target.value === 'all',
      };
    }

    if (event.target.name === WALLETS_SETTING) {
      updatedSetting = {
        allWallets: event.target.value === 'all',
      };
    }

    if (event.target.name === AA_UI_SETTING) {
      updatedSetting = {
        separateSmartWalletAndSigner: event.target.value === 'both',
      };
    }

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          accountAbstraction: {
            ...settings[activeEnvironmentType].sdk.accountAbstraction,
            ...updatedSetting,
          },
        },
      },
    });
  };

  const removeZeroDevProvider = (index: number) => {
    setZeroDevProvider((prev) => {
      const multichainAccountAbstractionProviders = [
        ...(prev.multichainAccountAbstractionProviders || []),
      ];
      multichainAccountAbstractionProviders.splice(index, 1);
      return {
        ...prev,
        ...(index === 0 ? { clientId: '' } : {}),
        multichainAccountAbstractionProviders,
      };
    });
  };

  return (
    <Portal
      className='absolute w-2/3 bg-white h-screen right-0 top-0 px-8 pt-[60px] pb-24'
      handleClose={onClickClose}
    >
      <EnvironmentTabsLayout className='!left-0 !w-full'>
        <SideModalHeader
          Icon={AccountAbstractionCircularIcon}
          title={t('integrations.wallets.account_abstraction.name')}
          content={t('integrations.wallets.account_abstraction.full_content')}
          showClose
          onClose={onClickClose}
        />

        {hasMultipleProvidersEnabled && (
          <ErrorInfo
            className='mb-3'
            message={t(
              'integrations.wallets.account_abstraction.errors.no_multiple_provider_enabled',
            )}
          />
        )}

        <div className='flex flex-col gap-6 pt-2'>
          {enableAlchemyAccountAbstractionIntegration && (
            <ProviderControlTemplate
              icon={<AlchemyIcon />}
              title={t('integrations.wallets.account_abstraction.alchemy.name')}
              subTitle={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Trans i18nKey='integrations.wallets.account_abstraction.alchemy.docs'>
                  {'You will need to create an account with '}
                  <a
                    className='font-bold text-primary-1'
                    href='https://alchemy.com/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Alchemy
                  </a>
                  . Enter your App ID here and we&apos;ll handle the rest.
                </Trans>
              }
              toggle={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Toggle
                  withIcon
                  id='alchemy'
                  checked={Boolean(alchemyProvider?.enabledAt)}
                  variant={
                    savedAlchemyProvider.enabledAt ? 'success' : 'primary'
                  }
                  handleChange={() =>
                    handleOnToggleEnabledAt(setAlchemyProvider)
                  }
                  ariaLabel={t(
                    'integrations.wallets.account_abstraction.alchemy.toggle_aria_label',
                  )}
                  disabled={!canEnableAccountAbstractionProviders}
                />
              }
              inputs={[
                <Input
                  name='alchemy-project-id-input'
                  id='alchemy-project-id-input'
                  label={t(
                    'integrations.wallets.account_abstraction.alchemy.provider_id_field.label',
                  )}
                  value={alchemyProvider?.clientId || ''}
                  disableBottomMargin
                  onChange={(event) =>
                    handleOnChangePrimaryClientId(setAlchemyProvider, event)
                  }
                  disabled={!canEnableAccountAbstractionProviders}
                />,
              ]}
            />
          )}
          <ProviderControlTemplate
            icon={<ZeroDevIcon />}
            title={t('integrations.wallets.account_abstraction.zero_dev.name')}
            subTitle={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Trans
                i18nKey='integrations.wallets.account_abstraction.zero_dev.docs'
                components={{
                  docsLink: (
                    <a
                      className='font-bold text-primary-1'
                      href='https://docs.dynamic.xyz/embedded-wallets/add-account-abstraction'
                      target='_blank'
                      rel='noreferrer'
                    >
                      docs
                    </a>
                  ),
                  zerodevLink: (
                    <a
                      className='font-bold text-primary-1'
                      href='https://zerodev.app/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Zerodev
                    </a>
                  ),
                }}
              />
            }
            toggle={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Toggle
                ariaLabel={t(
                  'integrations.wallets.account_abstraction.zero_dev.toggle_aria_label',
                )}
                withIcon
                id='zero-dev'
                checked={Boolean(zeroDevProvider.enabledAt)}
                variant={savedZeroDevProvider.enabledAt ? 'success' : 'primary'}
                handleChange={() => handleOnToggleEnabledAt(setZeroDevProvider)}
                disabled={!canEnableAccountAbstractionProviders}
              />
            }
            inputs={[
              ...(zeroDevProvider.multichainAccountAbstractionProviders?.length
                ? zeroDevProvider.multichainAccountAbstractionProviders
                : [{ chain: '', clientId: '' }]
              ).map((provider, index) => {
                const savedNetwork = getSavedProviderNetwork(provider.clientId);
                const NetworkIcon =
                  savedNetwork &&
                  getNetworkIcon(parseInt(`${savedNetwork}`, 10));

                return (
                  <div
                    className='flex flex-row gap-5'
                    // eslint-disable-next-line react/no-array-index-key
                    key={`zerodev-provider-${index}`}
                  >
                    <Input
                      // className='w-3/4'
                      data-testid='zero-dev-project-id-input'
                      name='zero-dev-project-id-input'
                      id='zero-dev-project-id-input'
                      label={t(
                        'integrations.wallets.account_abstraction.zero_dev.provider_id_field.label',
                      )}
                      disableBottomMargin
                      value={provider.clientId || ''}
                      onChange={(event) =>
                        handleOnChangeSecondaryClientIds(
                          setZeroDevProvider,
                          event,
                          index,
                        )
                      }
                      disabled={!canEnableAccountAbstractionProviders}
                    />
                    <div
                      className='flex items-center justify-center w-[50px]'
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      {hoveredIndex === index || !provider.clientId ? (
                        <TrashIcon
                          title={`remove-provider-${index}`}
                          className='cursor-pointer'
                          height={30}
                          width={30}
                          onClick={() => removeZeroDevProvider(index)}
                        />
                      ) : (
                        NetworkIcon && (
                          <NetworkIcon
                            className='h-[30px] w-[30px]'
                            title={`${provider.chain}-icon`}
                          />
                        )
                      )}
                    </div>
                  </div>
                );
              }),
              <div className='w-[25%]'>
                {enableMultichainAccountAbstraction && (
                  <Button
                    onClick={handleAddingMultichainProvider}
                    disabled={!shouldEnableAddProviderButton}
                    text={t(
                      'integrations.wallets.account_abstraction.zero_dev.add_provider',
                    )}
                  />
                )}
              </div>,
              !!errorMessage && <ErrorInfo message={errorMessage} />,
            ]}
          />{' '}
          <div className='flex flex-col gap-3'>
            <Typography variant='paragraph-3' weight='medium'>
              {t('integrations.wallets.account_abstraction.settings.title')}
            </Typography>
            <div className='flex flex-col divide-y gap-4'>
              <div className='flex flex-col pt-4 gap-2'>
                <RadioButton
                  name={USERS_SETTING}
                  id='new-users-only'
                  label={t(
                    'integrations.wallets.account_abstraction.settings.new_users_only.label',
                  )}
                  description={t(
                    'integrations.wallets.account_abstraction.settings.new_users_only.description',
                  )}
                  value='new'
                  checked={
                    !settings[activeEnvironmentType].sdk.accountAbstraction
                      ?.allUsers
                  }
                  onChange={handleAASetting}
                />

                <RadioButton
                  name={USERS_SETTING}
                  id='all-users'
                  label={t(
                    'integrations.wallets.account_abstraction.settings.all_users.label',
                  )}
                  description={t(
                    'integrations.wallets.account_abstraction.settings.all_users.description',
                  )}
                  value='all'
                  checked={
                    settings[activeEnvironmentType].sdk.accountAbstraction
                      ?.allUsers ?? false
                  }
                  onChange={handleAASetting}
                />
              </div>

              <div className='flex flex-col pt-4 gap-2'>
                <RadioButton
                  name={WALLETS_SETTING}
                  id='embbeded-wallets-only'
                  label={t(
                    'integrations.wallets.account_abstraction.settings.embedded_wallets_only.label',
                  )}
                  description={t(
                    'integrations.wallets.account_abstraction.settings.embedded_wallets_only.description',
                  )}
                  value='embedded'
                  checked={
                    !settings[activeEnvironmentType].sdk.accountAbstraction
                      ?.allWallets
                  }
                  onChange={handleAASetting}
                />

                <RadioButton
                  name={WALLETS_SETTING}
                  id='all-wallets'
                  label={t(
                    'integrations.wallets.account_abstraction.settings.all_wallets.label',
                  )}
                  description={t(
                    'integrations.wallets.account_abstraction.settings.all_wallets.description',
                  )}
                  value='all'
                  checked={
                    settings[activeEnvironmentType].sdk.accountAbstraction
                      ?.allWallets ?? false
                  }
                  onChange={handleAASetting}
                />
              </div>

              <div className='flex flex-col pt-4 gap-2'>
                <RadioButton
                  name={AA_UI_SETTING}
                  id='aa-only-ui'
                  label={t(
                    'integrations.wallets.account_abstraction.settings.show_aa_only.label',
                  )}
                  description={t(
                    'integrations.wallets.account_abstraction.settings.show_aa_only.description',
                  )}
                  value='single'
                  checked={
                    !settings[activeEnvironmentType].sdk.accountAbstraction
                      ?.separateSmartWalletAndSigner
                  }
                  onChange={handleAASetting}
                />

                <RadioButton
                  name={AA_UI_SETTING}
                  id='aa-and-embedded-ui'
                  label={t(
                    'integrations.wallets.account_abstraction.settings.show_both_aa_and_eoa.label',
                  )}
                  description={t(
                    'integrations.wallets.account_abstraction.settings.show_both_aa_and_eoa.description',
                  )}
                  value='both'
                  checked={
                    settings[activeEnvironmentType].sdk.accountAbstraction
                      ?.separateSmartWalletAndSigner ?? false
                  }
                  onChange={handleAASetting}
                />
              </div>
            </div>
          </div>
        </div>

        <AnimatePresence>
          {(hasChanges || isLoading) && !hasMultipleProvidersEnabled && (
            <ConfirmToast
              confirmationText={t('design_page.confirmButton')}
              cancelText={t('design_page.cancelButton')}
              message={t('design_page.confirmMessage')}
              onConfirm={onClickSave}
              loading={isLoading}
              onCancel={onClickCancelChanges}
              className='!w-10/12 !max-w-max'
            />
          )}
        </AnimatePresence>
      </EnvironmentTabsLayout>
    </Portal>
  );
};
