import { FC, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Section, SingleToggleCard } from '@dynamic-labs/northstar';

import { SideDrawer } from '../../../../../components/SideDrawer';
import { AdminIcon } from '../../../../../../icons';

import { EnsSubdomainsDrawer } from './EnsSubdomainsDrawer';
import styles from './EnsSubdomainsSection.module.scss';

export type EnsSubdomainsSectionProps = {
  disabled: boolean;
};

export const EnsSubdomainsSection: FC<EnsSubdomainsSectionProps> = ({
  disabled,
}) => {
  const { t } = useTranslation();
  const { enableEnsSubdomains } = useFlags();

  // This will be loaded from settings
  const enableEnsSubdomainsSetting = false;

  // This will initialize from settings
  const [toggleEnabled, setToggleEnabled] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  /** Enabling the toggle should open  */
  const onToggleChange = useCallback(() => {
    if (toggleEnabled) {
      setToggleEnabled(false);
    } else {
      setToggleEnabled(true);

      // This will be enabled once settings are set up but currently removed
      // because it can't be tested.
      // setShowDrawer(true);
    }
  }, [toggleEnabled]);

  const onSettingsClick = useCallback(() => {
    setShowDrawer(true);
  }, []);

  /** Closing the drawer without setting ENS domain should reset toggle */
  const onDrawerClose = useCallback(() => {
    setShowDrawer(false);
    if (!enableEnsSubdomainsSetting) {
      setToggleEnabled(false);
    }
  }, [enableEnsSubdomainsSetting]);

  /**
   * If subdomains are already enabled, they can't be
   * disabled unless the top level subdomain is removed.
   */
  const canEditSubdomains = !enableEnsSubdomainsSetting;

  if (!enableEnsSubdomains) {
    return;
  }

  return (
    <Section
      title={t('v2.page.log_in_methods.ens_subdomains.title')}
      description={t('v2.page.log_in_methods.ens_subdomains.description')}
    >
      <SingleToggleCard
        accordionKey='ens-subdomains-toggle'
        title={t('v2.page.log_in_methods.ens_subdomains.toggle.title')}
        allowExpand={false}
        onCardClick={toggleEnabled ? onSettingsClick : undefined}
        inputProps={{
          checked: toggleEnabled,
          disabled: !canEditSubdomains,
          id: 'ens-subdomains-toggle',
          isSaved: toggleEnabled === enableEnsSubdomainsSetting,
          onChange: onToggleChange,
          type: 'toggle',
        }}
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
      />

      <SideDrawer
        handleClose={onDrawerClose}
        isOpen={showDrawer && !disabled}
        title={t('v2.page.log_in_methods.ens_subdomains.title')}
        description={t(
          'v2.page.log_in_methods.ens_subdomains.drawer.description',
        )}
      >
        <EnsSubdomainsDrawer />
      </SideDrawer>
    </Section>
  );
};
