/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    CompletePasskeyRecoveryRequest,
    CompletePasskeyRecoveryRequestFromJSON,
    CompletePasskeyRecoveryRequestToJSON,
    ConnectRequest,
    ConnectRequestFromJSON,
    ConnectRequestToJSON,
    CreateEmbeddedWalletsRequest,
    CreateEmbeddedWalletsRequestFromJSON,
    CreateEmbeddedWalletsRequestToJSON,
    CreateWalletAccountRequest,
    CreateWalletAccountRequestFromJSON,
    CreateWalletAccountRequestToJSON,
    Currency,
    CurrencyFromJSON,
    CurrencyToJSON,
    DeleteEmbeddedWalletsRequest,
    DeleteEmbeddedWalletsRequestFromJSON,
    DeleteEmbeddedWalletsRequestToJSON,
    EmailProviderResponse,
    EmailProviderResponseFromJSON,
    EmailProviderResponseToJSON,
    EmailVerificationCreateRequest,
    EmailVerificationCreateRequestFromJSON,
    EmailVerificationCreateRequestToJSON,
    EmailVerificationCreateResponse,
    EmailVerificationCreateResponseFromJSON,
    EmailVerificationCreateResponseToJSON,
    EmailVerificationRetryRequest,
    EmailVerificationRetryRequestFromJSON,
    EmailVerificationRetryRequestToJSON,
    EmailVerificationVerifyRequest,
    EmailVerificationVerifyRequestFromJSON,
    EmailVerificationVerifyRequestToJSON,
    EmbeddedWalletAuthToken,
    EmbeddedWalletAuthTokenFromJSON,
    EmbeddedWalletAuthTokenToJSON,
    EmbeddedWalletAuthType,
    EmbeddedWalletAuthTypeFromJSON,
    EmbeddedWalletAuthTypeToJSON,
    EmbeddedWalletChainEnum,
    EmbeddedWalletChainEnumFromJSON,
    EmbeddedWalletChainEnumToJSON,
    EmbeddedWalletPasscodeClaimRequest,
    EmbeddedWalletPasscodeClaimRequestFromJSON,
    EmbeddedWalletPasscodeClaimRequestToJSON,
    EmbeddedWalletSecret,
    EmbeddedWalletSecretFromJSON,
    EmbeddedWalletSecretToJSON,
    ExportEmbeddedWalletResponse,
    ExportEmbeddedWalletResponseFromJSON,
    ExportEmbeddedWalletResponseToJSON,
    ExternalAuthSigninRequest,
    ExternalAuthSigninRequestFromJSON,
    ExternalAuthSigninRequestToJSON,
    FarcasterSignInRequest,
    FarcasterSignInRequestFromJSON,
    FarcasterSignInRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    ForbiddenWithErrorAndPayload,
    ForbiddenWithErrorAndPayloadFromJSON,
    ForbiddenWithErrorAndPayloadToJSON,
    GetUserPasskeysResponse,
    GetUserPasskeysResponseFromJSON,
    GetUserPasskeysResponseToJSON,
    HealthcheckResponse,
    HealthcheckResponseFromJSON,
    HealthcheckResponseToJSON,
    InitEmailAuthRequest,
    InitEmailAuthRequestFromJSON,
    InitEmailAuthRequestToJSON,
    InitEmailAuthResponse,
    InitEmailAuthResponseFromJSON,
    InitEmailAuthResponseToJSON,
    InitPasskeyRecoveryRequest,
    InitPasskeyRecoveryRequestFromJSON,
    InitPasskeyRecoveryRequestToJSON,
    InitPasskeyRecoveryResponse,
    InitPasskeyRecoveryResponseFromJSON,
    InitPasskeyRecoveryResponseToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    JwksResponse,
    JwksResponseFromJSON,
    JwksResponseToJSON,
    MFAAuthPasskeyDeviceGetResponse,
    MFAAuthPasskeyDeviceGetResponseFromJSON,
    MFAAuthPasskeyDeviceGetResponseToJSON,
    MFAAuthPasskeyDevicePostRequest,
    MFAAuthPasskeyDevicePostRequestFromJSON,
    MFAAuthPasskeyDevicePostRequestToJSON,
    MFAAuthRecoveryDevicePostRequest,
    MFAAuthRecoveryDevicePostRequestFromJSON,
    MFAAuthRecoveryDevicePostRequestToJSON,
    MFAAuthTotpDevicePostRequest,
    MFAAuthTotpDevicePostRequestFromJSON,
    MFAAuthTotpDevicePostRequestToJSON,
    MFADevice,
    MFADeviceFromJSON,
    MFADeviceToJSON,
    MFAGetRecoveryCodesResponse,
    MFAGetRecoveryCodesResponseFromJSON,
    MFAGetRecoveryCodesResponseToJSON,
    MFAListDevicesResponse,
    MFAListDevicesResponseFromJSON,
    MFAListDevicesResponseToJSON,
    MFARegenRecoveryCodesResponse,
    MFARegenRecoveryCodesResponseFromJSON,
    MFARegenRecoveryCodesResponseToJSON,
    MFARegisterPasskeyDeviceGetResponse,
    MFARegisterPasskeyDeviceGetResponseFromJSON,
    MFARegisterPasskeyDeviceGetResponseToJSON,
    MFARegisterPasskeyDevicePostRequest,
    MFARegisterPasskeyDevicePostRequestFromJSON,
    MFARegisterPasskeyDevicePostRequestToJSON,
    MFARegisterTotpDeviceGetResponse,
    MFARegisterTotpDeviceGetResponseFromJSON,
    MFARegisterTotpDeviceGetResponseToJSON,
    MFARegisterTotpDevicePostRequest,
    MFARegisterTotpDevicePostRequestFromJSON,
    MFARegisterTotpDevicePostRequestToJSON,
    MFAUpdateDeviceRequest,
    MFAUpdateDeviceRequestFromJSON,
    MFAUpdateDeviceRequestToJSON,
    MergeUserConflictResolutions,
    MergeUserConflictResolutionsFromJSON,
    MergeUserConflictResolutionsToJSON,
    NetworkConfigurationResponse,
    NetworkConfigurationResponseFromJSON,
    NetworkConfigurationResponseToJSON,
    NonceResponse,
    NonceResponseFromJSON,
    NonceResponseToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    OauthInitAuthRequest,
    OauthInitAuthRequestFromJSON,
    OauthInitAuthRequestToJSON,
    OauthProviderLoginUrl,
    OauthProviderLoginUrlFromJSON,
    OauthProviderLoginUrlToJSON,
    OauthRequest,
    OauthRequestFromJSON,
    OauthRequestToJSON,
    OauthResultRequest,
    OauthResultRequestFromJSON,
    OauthResultRequestToJSON,
    OauthResultResponse,
    OauthResultResponseFromJSON,
    OauthResultResponseToJSON,
    PrefetchRequest,
    PrefetchRequestFromJSON,
    PrefetchRequestToJSON,
    ProjectSettings,
    ProjectSettingsFromJSON,
    ProjectSettingsToJSON,
    ProviderEnum,
    ProviderEnumFromJSON,
    ProviderEnumToJSON,
    PublishEvents,
    PublishEventsFromJSON,
    PublishEventsToJSON,
    RegisterEmbeddedWalletSessionKeyResponse,
    RegisterEmbeddedWalletSessionKeyResponseFromJSON,
    RegisterEmbeddedWalletSessionKeyResponseToJSON,
    RegisterSessionKeyRequest,
    RegisterSessionKeyRequestFromJSON,
    RegisterSessionKeyRequestToJSON,
    ScanWebsiteUrlRequest,
    ScanWebsiteUrlRequestFromJSON,
    ScanWebsiteUrlRequestToJSON,
    ScanWebsiteUrlResponse,
    ScanWebsiteUrlResponseFromJSON,
    ScanWebsiteUrlResponseToJSON,
    SdkSettingsRequest,
    SdkSettingsRequestFromJSON,
    SdkSettingsRequestToJSON,
    SdkUser,
    SdkUserFromJSON,
    SdkUserToJSON,
    SimulateEVMTransactionRequest,
    SimulateEVMTransactionRequestFromJSON,
    SimulateEVMTransactionRequestToJSON,
    SimulateSVMTransactionRequest,
    SimulateSVMTransactionRequestFromJSON,
    SimulateSVMTransactionRequestToJSON,
    SimulateTransactionResponse,
    SimulateTransactionResponseFromJSON,
    SimulateTransactionResponseToJSON,
    SmsVerificationCreateRequest,
    SmsVerificationCreateRequestFromJSON,
    SmsVerificationCreateRequestToJSON,
    SmsVerificationCreateResponse,
    SmsVerificationCreateResponseFromJSON,
    SmsVerificationCreateResponseToJSON,
    SmsVerificationRetryRequest,
    SmsVerificationRetryRequestFromJSON,
    SmsVerificationRetryRequestToJSON,
    SmsVerificationVerifyRequest,
    SmsVerificationVerifyRequestFromJSON,
    SmsVerificationVerifyRequestToJSON,
    SolanaTransactionOptimizationRequest,
    SolanaTransactionOptimizationRequestFromJSON,
    SolanaTransactionOptimizationRequestToJSON,
    SolanaTransactionOptimizationResponse,
    SolanaTransactionOptimizationResponseFromJSON,
    SolanaTransactionOptimizationResponseToJSON,
    SupportedOnrampsResponse,
    SupportedOnrampsResponseFromJSON,
    SupportedOnrampsResponseToJSON,
    TelegramPostRequest,
    TelegramPostRequestFromJSON,
    TelegramPostRequestToJSON,
    TokenBalance,
    TokenBalanceFromJSON,
    TokenBalanceToJSON,
    TooManyRequests,
    TooManyRequestsFromJSON,
    TooManyRequestsToJSON,
    TurnkeyCreateWalletAccountsRequestBody,
    TurnkeyCreateWalletAccountsRequestBodyFromJSON,
    TurnkeyCreateWalletAccountsRequestBodyToJSON,
    TurnkeyDeleteEmbeddedWalletsRequestBody,
    TurnkeyDeleteEmbeddedWalletsRequestBodyFromJSON,
    TurnkeyDeleteEmbeddedWalletsRequestBodyToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
    UpdateRecoveryEmailRequest,
    UpdateRecoveryEmailRequestFromJSON,
    UpdateRecoveryEmailRequestToJSON,
    UpdateSelfResponse,
    UpdateSelfResponseFromJSON,
    UpdateSelfResponseToJSON,
    UpdateUserPasskeyRequest,
    UpdateUserPasskeyRequestFromJSON,
    UpdateUserPasskeyRequestToJSON,
    UserFields,
    UserFieldsFromJSON,
    UserFieldsToJSON,
    UserPasskey,
    UserPasskeyFromJSON,
    UserPasskeyToJSON,
    UserWalletSelectionRequest,
    UserWalletSelectionRequestFromJSON,
    UserWalletSelectionRequestToJSON,
    VerifyRequest,
    VerifyRequestFromJSON,
    VerifyRequestToJSON,
    VerifyResponse,
    VerifyResponseFromJSON,
    VerifyResponseToJSON,
    VerifyUnlinkRequest,
    VerifyUnlinkRequestFromJSON,
    VerifyUnlinkRequestToJSON,
} from '../models';

export interface AuthMfaPasskeyDeviceRequest {
    environmentId: string;
    mFAAuthPasskeyDevicePostRequest: MFAAuthPasskeyDevicePostRequest;
}

export interface AuthMfaPasskeyDeviceChallengeRequest {
    environmentId: string;
}

export interface AuthMfaPasskeyDeviceOptionsRequest {
    environmentId: string;
}

export interface AuthMfaRecoveryRequest {
    environmentId: string;
    mFAAuthRecoveryDevicePostRequest: MFAAuthRecoveryDevicePostRequest;
}

export interface AuthMfaRecoveryOptionsRequest {
    environmentId: string;
}

export interface AuthMfaTotpDeviceRequest {
    environmentId: string;
    mFAAuthTotpDevicePostRequest: MFAAuthTotpDevicePostRequest;
}

export interface AuthMfaTotpDeviceOptionsRequest {
    environmentId: string;
}

export interface ClaimEmbeddedWalletRequest {
    environmentId: string;
    embeddedWalletPasscodeClaimRequest: EmbeddedWalletPasscodeClaimRequest;
}

export interface ClaimEmbeddedWalletOptionsRequest {
    environmentId: string;
}

export interface CompletePasskeyRecoveryOperationRequest {
    environmentId: string;
    completePasskeyRecoveryRequest: CompletePasskeyRecoveryRequest;
}

export interface CreateEmailVerificationRequest {
    environmentId: string;
    emailVerificationCreateRequest: EmailVerificationCreateRequest;
}

export interface CreateEmailVerificationOptionsRequest {
    environmentId: string;
}

export interface CreateEmbeddedWalletsOperationRequest {
    environmentId: string;
    createEmbeddedWalletsRequest: CreateEmbeddedWalletsRequest;
}

export interface CreateEmbeddedWalletsOptionsRequest {
    environmentId: string;
}

export interface CreateNewRecoveryCodesRequest {
    environmentId: string;
}

export interface CreateSmsVerificationRequest {
    environmentId: string;
    smsVerificationCreateRequest: SmsVerificationCreateRequest;
}

export interface CreateSmsVerificationOptionsRequest {
    environmentId: string;
}

export interface CreateVisitRequest {
    environmentId: string;
    connectRequest: ConnectRequest;
}

export interface CreateWalletAccountOperationRequest {
    environmentId: string;
    createWalletAccountRequest: CreateWalletAccountRequest;
}

export interface DeleteEmbeddedWalletsOperationRequest {
    environmentId: string;
    deleteEmbeddedWalletsRequest?: DeleteEmbeddedWalletsRequest;
}

export interface DeleteMfaDeviceRequest {
    environmentId: string;
    mfaDeviceId: string;
    xMfaAuthToken: string;
}

export interface EmailAuthOptionsRequest {
    environmentId: string;
}

export interface EmailProviderOptionsRequest {
    environmentId: string;
}

export interface EmbeddedWalletDeleteRequestOptionsRequest {
    environmentId: string;
}

export interface EmbeddedWalletExportRequest {
    environmentId: string;
    walletId: string;
    activityId: string;
}

export interface EmbeddedWalletExportOptionsRequest {
    environmentId: string;
    walletId: string;
    activityId: string;
}

export interface EventsOptionsRequest {
    environmentId: string;
}

export interface ExternalAuthSigninOperationRequest {
    environmentId: string;
    externalAuthSigninRequest: ExternalAuthSigninRequest;
}

export interface ExternalAuthSigninOptionsRequest {
    environmentId: string;
}

export interface ExternalAuthVerifyRequest {
    environmentId: string;
    externalAuthSigninRequest: ExternalAuthSigninRequest;
}

export interface ExternalAuthVerifyOptionsRequest {
    environmentId: string;
}

export interface FarcasterSignInOperationRequest {
    environmentId: string;
    farcasterSignInRequest?: FarcasterSignInRequest;
}

export interface FarcasterSignInOptionsRequest {
    environmentId: string;
}

export interface FarcasterVerifyRequest {
    environmentId: string;
    farcasterSignInRequest?: FarcasterSignInRequest;
}

export interface FarcasterVerifyOptionsRequest {
    environmentId: string;
}

export interface GetAccountBalancesRequest {
    environmentId: string;
    chainName: GetAccountBalancesChainNameEnum;
    accountAddress: string;
    networkId?: GetAccountBalancesNetworkIdEnum;
    includePrices?: boolean;
    includeNative?: boolean;
}

export interface GetAccountBalancesOptionsRequest {
    environmentId: string;
    chainName: GetAccountBalancesOptionsChainNameEnum;
    networkId: number;
    accountAddress: string;
}

export interface GetAuthTokenRequest {
    environmentId: string;
    type?: EmbeddedWalletAuthType;
}

export interface GetAuthTokenOptsRequest {
    environmentId: string;
}

export interface GetAuthenticatedUserRequest {
    environmentId: string;
}

export interface GetCreateWalletAccountRequestRequest {
    environmentId: string;
    chain: EmbeddedWalletChainEnum;
}

export interface GetEmailProviderRequest {
    environmentId: string;
    email: string;
}

export interface GetEmbeddedWalletBackupOptionRequest {
    environmentId: string;
}

export interface GetEmbeddedWalletBackupsRequest {
    environmentId: string;
}

export interface GetEmbeddedWalletPasscodeRequest {
    environmentId: string;
}

export interface GetEmbeddedWalletPasscodeOptionRequest {
    environmentId: string;
}

export interface GetEmbeddedWalletsDeleteRequestRequest {
    environmentId: string;
}

export interface GetEnvironmentSettingsRequest {
    environmentId: string;
    sdkVersion?: string;
}

export interface GetHealthcheckRequest {
    environmentId: string;
}

export interface GetHealthcheckOptionsRequest {
    environmentId: string;
}

export interface GetJwksByEnvironmentIdRequest {
    environmentId: string;
}

export interface GetMfaDeviceRequest {
    environmentId: string;
    mfaDeviceId: string;
}

export interface GetMfaDeviceOptionsRequest {
    environmentId: string;
    mfaDeviceId: string;
}

export interface GetNetworksConfigurationByEnvIdRequest {
    environmentId: string;
}

export interface GetNonceRequest {
    environmentId: string;
}

export interface GetRecoveryCodesRequest {
    environmentId: string;
}

export interface GetSupportedOnrampsRequest {
    environmentId: string;
    walletAddress: string;
    chain: string;
    networkId?: string;
    token?: string;
}

export interface GetTelegramAuthRequest {
    environmentId: string;
    state: string;
}

export interface GetUserMfaDevicesRequest {
    environmentId: string;
}

export interface GetUserPasskeysRequest {
    environmentId: string;
}

export interface InitAuthRequest {
    environmentId: string;
    providerType: ProviderEnum;
    oauthInitAuthRequest: OauthInitAuthRequest;
}

export interface InitAuthOptionsRequest {
    environmentId: string;
    providerType: ProviderEnum;
}

export interface InitEmailAuthOperationRequest {
    environmentId: string;
    initEmailAuthRequest: InitEmailAuthRequest;
}

export interface InitPasskeyRecoveryOperationRequest {
    environmentId: string;
    initPasskeyRecoveryRequest: InitPasskeyRecoveryRequest;
}

export interface JwksOptionsRequest {
    environmentId: string;
}

export interface ListMfaDevicesOptionsRequest {
    environmentId: string;
}

export interface LogDynamicSdkSettingsRequest {
    environmentId: string;
    sdkSettingsRequest: SdkSettingsRequest;
}

export interface MergeUsersRequest {
    environmentId: string;
}

export interface MergeUsersOptionsRequest {
    environmentId: string;
}

export interface NetworksOptionsRequest {
    environmentId: string;
}

export interface NonceOptionsRequest {
    environmentId: string;
}

export interface OauthAuthorizeHtmlRequest {
    environmentId: string;
    providerType: ProviderEnum;
    scope?: string;
    state?: string;
    redirectUri?: string;
}

export interface OauthAuthorizeHtmlOptionsRequest {
    environmentId: string;
    providerType: ProviderEnum;
}

export interface OauthLoginRequest {
    environmentId: string;
    providerType: ProviderEnum;
}

export interface OauthLoginOptionsRequest {
    environmentId: string;
    providerType: ProviderEnum;
}

export interface OauthLoginUrlRequest {
    environmentId: string;
    providerType: ProviderEnum;
}

export interface OauthLoginUrlOptionsRequest {
    environmentId: string;
    providerType: ProviderEnum;
}

export interface OauthRedirectAppleRequest {
    environmentId: string;
    code?: string;
    state?: string;
    idToken?: string;
    error?: string;
}

export interface OauthRedirectAppleOptionsRequest {
    environmentId: string;
}

export interface OauthRedirectCodeRequest {
    environmentId: string;
    providerType: ProviderEnum;
    code?: string;
    scope?: string;
    state?: string;
    authuser?: string;
    prompt?: string;
    error?: string;
}

export interface OauthRedirectOptionsRequest {
    environmentId: string;
    providerType: ProviderEnum;
}

export interface OauthResultOperationRequest {
    environmentId: string;
    providerType: ProviderEnum;
    oauthResultRequest: OauthResultRequest;
}

export interface OauthResultOptionsRequest {
    environmentId: string;
    providerType: ProviderEnum;
}

export interface OauthSignInRequest {
    environmentId: string;
    providerType: ProviderEnum;
    oauthRequest: OauthRequest;
}

export interface OauthSignInOptionsRequest {
    environmentId: string;
    providerType: ProviderEnum;
}

export interface OauthVerifyRequest {
    environmentId: string;
    providerType: ProviderEnum;
    oauthRequest: OauthRequest;
}

export interface OauthVerifyOptionsRequest {
    environmentId: string;
    providerType: ProviderEnum;
}

export interface OnrampsOptionsRequest {
    environmentId: string;
}

export interface OptimizeTransactionRequest {
    environmentId: string;
    solanaTransactionOptimizationRequest: SolanaTransactionOptimizationRequest;
}

export interface OptimizeTransactionOptionsRequest {
    environmentId: string;
}

export interface OptionsConnectRequest {
    environmentId: string;
}

export interface PasskeyRecoveryOptionsRequest {
    environmentId: string;
}

export interface PostTelegramAuthRequest {
    environmentId: string;
    telegramPostRequest: TelegramPostRequest;
}

export interface PrefetchOperationRequest {
    environmentId: string;
    prefetchRequest: PrefetchRequest;
}

export interface PublishEventRequest {
    environmentId: string;
    publishEvents: PublishEvents;
}

export interface RecoveryCodesOptionsRequest {
    environmentId: string;
}

export interface RecoveryEmailOptionsRequest {
    environmentId: string;
}

export interface RefreshAuthRequest {
    environmentId: string;
}

export interface RefreshOptionsRequest {
    environmentId: string;
}

export interface RegisterPasskeyMfaDeviceRequest {
    environmentId: string;
}

export interface RegisterPasskeyMfaDeviceOptionsRequest {
    environmentId: string;
}

export interface RegisterPasskeyMfaDeviceVerifyRequest {
    environmentId: string;
    mFARegisterPasskeyDevicePostRequest: MFARegisterPasskeyDevicePostRequest;
}

export interface RegisterSessionKeyOperationRequest {
    environmentId: string;
    registerSessionKeyRequest: RegisterSessionKeyRequest;
}

export interface RegisterTotpMfaDeviceRequest {
    environmentId: string;
}

export interface RegisterTotpMfaDeviceOptionsRequest {
    environmentId: string;
}

export interface RegisterTotpMfaDeviceVerifyRequest {
    environmentId: string;
    mFARegisterTotpDevicePostRequest: MFARegisterTotpDevicePostRequest;
}

export interface RetryEmailVerificationRequest {
    environmentId: string;
    emailVerificationRetryRequest: EmailVerificationRetryRequest;
}

export interface RetryEmailVerificationOptionsRequest {
    environmentId: string;
}

export interface RetrySmsVerificationRequest {
    environmentId: string;
    smsVerificationRetryRequest: SmsVerificationRetryRequest;
}

export interface RetrySmsVerificationOptionsRequest {
    environmentId: string;
}

export interface RevokeSessionRequest {
    environmentId: string;
}

export interface RevokeSessionOptionRequest {
    environmentId: string;
}

export interface ScanUrlRequest {
    environmentId: string;
    scanWebsiteUrlRequest: ScanWebsiteUrlRequest;
}

export interface ScanUrlOptionsRequest {
    environmentId: string;
}

export interface SdkSettingsOptionsRequest {
    environmentId: string;
}

export interface SelectUserWalletRequest {
    environmentId: string;
    userWalletSelectionRequest: UserWalletSelectionRequest;
}

export interface SelectUserWalletOptionsRequest {
    environmentId: string;
}

export interface SessionCheckRequest {
    environmentId: string;
}

export interface SessionCheckOptionsRequest {
    environmentId: string;
}

export interface SessionKeyOptionsRequest {
    environmentId: string;
}

export interface SettingsOptionsRequest {
    environmentId: string;
}

export interface SignInWithEmailVerificationRequest {
    environmentId: string;
    emailVerificationVerifyRequest: EmailVerificationVerifyRequest;
}

export interface SignInWithEmailVerificationOptionsRequest {
    environmentId: string;
}

export interface SignInWithSmsVerificationRequest {
    environmentId: string;
    smsVerificationVerifyRequest: SmsVerificationVerifyRequest;
}

export interface SignInWithSmsVerificationOptionsRequest {
    environmentId: string;
}

export interface SimulateEVMTransactionOperationRequest {
    environmentId: string;
    simulateEVMTransactionRequest: SimulateEVMTransactionRequest;
}

export interface SimulateEVMTransactionOptionsRequest {
    environmentId: string;
}

export interface SimulateSVMTransactionOperationRequest {
    environmentId: string;
    simulateSVMTransactionRequest: SimulateSVMTransactionRequest;
}

export interface SimulateSVMTransactionOptionsRequest {
    environmentId: string;
}

export interface TelegramAuthOptionsRequest {
    environmentId: string;
}

export interface TelegramCheckAuthRequest {
    environmentId: string;
    oauthResultRequest?: OauthResultRequest;
}

export interface TelegramCheckAuthOptionsRequest {
    environmentId: string;
}

export interface TelegramSignInRequest {
    environmentId: string;
    oauthResultRequest?: OauthResultRequest;
}

export interface TelegramSignInOptionsRequest {
    environmentId: string;
}

export interface TelegramVerifyRequest {
    environmentId: string;
    oauthResultRequest?: OauthResultRequest;
}

export interface TelegramVerifyOptionsRequest {
    environmentId: string;
}

export interface UpdateEmbeddedWalletRecoveryEmailRequest {
    environmentId: string;
    updateRecoveryEmailRequest: UpdateRecoveryEmailRequest;
}

export interface UpdatePasskeyRequest {
    environmentId: string;
    updateUserPasskeyRequest: UpdateUserPasskeyRequest;
}

export interface UpdateSelfRequest {
    environmentId: string;
    userFields: UserFields;
}

export interface UpdateUserMfaDeviceRequest {
    environmentId: string;
    mfaDeviceId: string;
    mFAUpdateDeviceRequest?: MFAUpdateDeviceRequest;
}

export interface UserPasskeysOptionsRequest {
    environmentId: string;
}

export interface UsersOptionsRequest {
    environmentId: string;
}

export interface VerifyOperationRequest {
    environmentId: string;
    verifyRequest: VerifyRequest;
}

export interface VerifyEmailVerificationRequest {
    environmentId: string;
    emailVerificationVerifyRequest: EmailVerificationVerifyRequest;
}

export interface VerifyEmailVerificationOptionsRequest {
    environmentId: string;
}

export interface VerifyLinkRequest {
    environmentId: string;
    verifyRequest: VerifyRequest;
}

export interface VerifyLinkOptionsRequest {
    environmentId: string;
}

export interface VerifyMergeUsersRequest {
    environmentId: string;
    mergeUserConflictResolutions: MergeUserConflictResolutions;
}

export interface VerifyMergeUsersOptionsRequest {
    environmentId: string;
}

export interface VerifyOptionsRequest {
    environmentId: string;
}

export interface VerifyPrefetchRequest {
    environmentId: string;
}

export interface VerifySmsVerificationRequest {
    environmentId: string;
    smsVerificationVerifyRequest: SmsVerificationVerifyRequest;
}

export interface VerifySmsVerificationOptionsRequest {
    environmentId: string;
}

export interface VerifyTransferRequest {
    environmentId: string;
    verifyRequest: VerifyRequest;
}

export interface VerifyTransferOptionsRequest {
    environmentId: string;
}

export interface VerifyUnlinkOperationRequest {
    environmentId: string;
    verifyUnlinkRequest: VerifyUnlinkRequest;
}

export interface VerifyUnlinkOptionsRequest {
    environmentId: string;
}

export interface WalletAccountOptionsRequest {
    environmentId: string;
}

export interface WalletAccountRequestOptionsRequest {
    environmentId: string;
}

/**
 * 
 */
export class SDKApi extends runtime.BaseAPI {

    /**
     * Upgrade JWT scope with valid MFA session
     */
    async authMfaPasskeyDeviceRaw(requestParameters: AuthMfaPasskeyDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling authMfaPasskeyDevice.');
        }

        if (requestParameters.mFAAuthPasskeyDevicePostRequest === null || requestParameters.mFAAuthPasskeyDevicePostRequest === undefined) {
            throw new runtime.RequiredError('mFAAuthPasskeyDevicePostRequest','Required parameter requestParameters.mFAAuthPasskeyDevicePostRequest was null or undefined when calling authMfaPasskeyDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/auth/passkey`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MFAAuthPasskeyDevicePostRequestToJSON(requestParameters.mFAAuthPasskeyDevicePostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Upgrade JWT scope with valid MFA session
     */
    async authMfaPasskeyDevice(requestParameters: AuthMfaPasskeyDeviceRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.authMfaPasskeyDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Auth Passkey MFA device
     */
    async authMfaPasskeyDeviceChallengeRaw(requestParameters: AuthMfaPasskeyDeviceChallengeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MFAAuthPasskeyDeviceGetResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling authMfaPasskeyDeviceChallenge.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/auth/passkey`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MFAAuthPasskeyDeviceGetResponseFromJSON(jsonValue));
    }

    /**
     * Auth Passkey MFA device
     */
    async authMfaPasskeyDeviceChallenge(requestParameters: AuthMfaPasskeyDeviceChallengeRequest, initOverrides?: RequestInit): Promise<MFAAuthPasskeyDeviceGetResponse> {
        const response = await this.authMfaPasskeyDeviceChallengeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async authMfaPasskeyDeviceOptionsRaw(requestParameters: AuthMfaPasskeyDeviceOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling authMfaPasskeyDeviceOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/auth/passkey`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async authMfaPasskeyDeviceOptions(requestParameters: AuthMfaPasskeyDeviceOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.authMfaPasskeyDeviceOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Upgrade JWT scope with valid MFA session
     */
    async authMfaRecoveryRaw(requestParameters: AuthMfaRecoveryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling authMfaRecovery.');
        }

        if (requestParameters.mFAAuthRecoveryDevicePostRequest === null || requestParameters.mFAAuthRecoveryDevicePostRequest === undefined) {
            throw new runtime.RequiredError('mFAAuthRecoveryDevicePostRequest','Required parameter requestParameters.mFAAuthRecoveryDevicePostRequest was null or undefined when calling authMfaRecovery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/auth/recovery`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MFAAuthRecoveryDevicePostRequestToJSON(requestParameters.mFAAuthRecoveryDevicePostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Upgrade JWT scope with valid MFA session
     */
    async authMfaRecovery(requestParameters: AuthMfaRecoveryRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.authMfaRecoveryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async authMfaRecoveryOptionsRaw(requestParameters: AuthMfaRecoveryOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling authMfaRecoveryOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/auth/recovery`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async authMfaRecoveryOptions(requestParameters: AuthMfaRecoveryOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.authMfaRecoveryOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Upgrade JWT scope with valid MFA session
     */
    async authMfaTotpDeviceRaw(requestParameters: AuthMfaTotpDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling authMfaTotpDevice.');
        }

        if (requestParameters.mFAAuthTotpDevicePostRequest === null || requestParameters.mFAAuthTotpDevicePostRequest === undefined) {
            throw new runtime.RequiredError('mFAAuthTotpDevicePostRequest','Required parameter requestParameters.mFAAuthTotpDevicePostRequest was null or undefined when calling authMfaTotpDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/auth/totp`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MFAAuthTotpDevicePostRequestToJSON(requestParameters.mFAAuthTotpDevicePostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Upgrade JWT scope with valid MFA session
     */
    async authMfaTotpDevice(requestParameters: AuthMfaTotpDeviceRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.authMfaTotpDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async authMfaTotpDeviceOptionsRaw(requestParameters: AuthMfaTotpDeviceOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling authMfaTotpDeviceOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/auth/totp`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async authMfaTotpDeviceOptions(requestParameters: AuthMfaTotpDeviceOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.authMfaTotpDeviceOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Claim a pregenerated embedded wallet
     */
    async claimEmbeddedWalletRaw(requestParameters: ClaimEmbeddedWalletRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling claimEmbeddedWallet.');
        }

        if (requestParameters.embeddedWalletPasscodeClaimRequest === null || requestParameters.embeddedWalletPasscodeClaimRequest === undefined) {
            throw new runtime.RequiredError('embeddedWalletPasscodeClaimRequest','Required parameter requestParameters.embeddedWalletPasscodeClaimRequest was null or undefined when calling claimEmbeddedWallet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/passcode/claim`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EmbeddedWalletPasscodeClaimRequestToJSON(requestParameters.embeddedWalletPasscodeClaimRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Claim a pregenerated embedded wallet
     */
    async claimEmbeddedWallet(requestParameters: ClaimEmbeddedWalletRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.claimEmbeddedWalletRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async claimEmbeddedWalletOptionsRaw(requestParameters: ClaimEmbeddedWalletOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling claimEmbeddedWalletOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/passcode/claim`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async claimEmbeddedWalletOptions(requestParameters: ClaimEmbeddedWalletOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.claimEmbeddedWalletOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Completes the passkey recovery process for a user\'s passkey embedded wallet
     * Completes the passkey recovery process for a user\'s passkey embedded wallet
     */
    async completePasskeyRecoveryRaw(requestParameters: CompletePasskeyRecoveryOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling completePasskeyRecovery.');
        }

        if (requestParameters.completePasskeyRecoveryRequest === null || requestParameters.completePasskeyRecoveryRequest === undefined) {
            throw new runtime.RequiredError('completePasskeyRecoveryRequest','Required parameter requestParameters.completePasskeyRecoveryRequest was null or undefined when calling completePasskeyRecovery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/passkeyRecovery`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompletePasskeyRecoveryRequestToJSON(requestParameters.completePasskeyRecoveryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Completes the passkey recovery process for a user\'s passkey embedded wallet
     * Completes the passkey recovery process for a user\'s passkey embedded wallet
     */
    async completePasskeyRecovery(requestParameters: CompletePasskeyRecoveryOperationRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.completePasskeyRecoveryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initialize email verification process
     */
    async createEmailVerificationRaw(requestParameters: CreateEmailVerificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmailVerificationCreateResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createEmailVerification.');
        }

        if (requestParameters.emailVerificationCreateRequest === null || requestParameters.emailVerificationCreateRequest === undefined) {
            throw new runtime.RequiredError('emailVerificationCreateRequest','Required parameter requestParameters.emailVerificationCreateRequest was null or undefined when calling createEmailVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/emailVerifications/create`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailVerificationCreateRequestToJSON(requestParameters.emailVerificationCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailVerificationCreateResponseFromJSON(jsonValue));
    }

    /**
     * Initialize email verification process
     */
    async createEmailVerification(requestParameters: CreateEmailVerificationRequest, initOverrides?: RequestInit): Promise<EmailVerificationCreateResponse> {
        const response = await this.createEmailVerificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async createEmailVerificationOptionsRaw(requestParameters: CreateEmailVerificationOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createEmailVerificationOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/emailVerifications/create`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async createEmailVerificationOptions(requestParameters: CreateEmailVerificationOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createEmailVerificationOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Create one or more embedded wallets for a user
     * Create one or more new embedded wallets for a user
     */
    async createEmbeddedWalletsRaw(requestParameters: CreateEmbeddedWalletsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createEmbeddedWallets.');
        }

        if (requestParameters.createEmbeddedWalletsRequest === null || requestParameters.createEmbeddedWalletsRequest === undefined) {
            throw new runtime.RequiredError('createEmbeddedWalletsRequest','Required parameter requestParameters.createEmbeddedWalletsRequest was null or undefined when calling createEmbeddedWallets.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEmbeddedWalletsRequestToJSON(requestParameters.createEmbeddedWalletsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Create one or more embedded wallets for a user
     * Create one or more new embedded wallets for a user
     */
    async createEmbeddedWallets(requestParameters: CreateEmbeddedWalletsOperationRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.createEmbeddedWalletsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async createEmbeddedWalletsOptionsRaw(requestParameters: CreateEmbeddedWalletsOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createEmbeddedWalletsOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async createEmbeddedWalletsOptions(requestParameters: CreateEmbeddedWalletsOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createEmbeddedWalletsOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Generate new recovery codes for user
     */
    async createNewRecoveryCodesRaw(requestParameters: CreateNewRecoveryCodesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MFARegenRecoveryCodesResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createNewRecoveryCodes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/recovery`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MFARegenRecoveryCodesResponseFromJSON(jsonValue));
    }

    /**
     * Generate new recovery codes for user
     */
    async createNewRecoveryCodes(requestParameters: CreateNewRecoveryCodesRequest, initOverrides?: RequestInit): Promise<MFARegenRecoveryCodesResponse> {
        const response = await this.createNewRecoveryCodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initialize sms verification process
     */
    async createSmsVerificationRaw(requestParameters: CreateSmsVerificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SmsVerificationCreateResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createSmsVerification.');
        }

        if (requestParameters.smsVerificationCreateRequest === null || requestParameters.smsVerificationCreateRequest === undefined) {
            throw new runtime.RequiredError('smsVerificationCreateRequest','Required parameter requestParameters.smsVerificationCreateRequest was null or undefined when calling createSmsVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/smsVerifications/create`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SmsVerificationCreateRequestToJSON(requestParameters.smsVerificationCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SmsVerificationCreateResponseFromJSON(jsonValue));
    }

    /**
     * Initialize sms verification process
     */
    async createSmsVerification(requestParameters: CreateSmsVerificationRequest, initOverrides?: RequestInit): Promise<SmsVerificationCreateResponse> {
        const response = await this.createSmsVerificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async createSmsVerificationOptionsRaw(requestParameters: CreateSmsVerificationOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createSmsVerificationOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/smsVerifications/create`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async createSmsVerificationOptions(requestParameters: CreateSmsVerificationOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createSmsVerificationOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint to send minimal wallet information for a connect-only or connect-first visitor.
     * Create a visit
     */
    async createVisitRaw(requestParameters: CreateVisitRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createVisit.');
        }

        if (requestParameters.connectRequest === null || requestParameters.connectRequest === undefined) {
            throw new runtime.RequiredError('connectRequest','Required parameter requestParameters.connectRequest was null or undefined when calling createVisit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/connect`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectRequestToJSON(requestParameters.connectRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint to send minimal wallet information for a connect-only or connect-first visitor.
     * Create a visit
     */
    async createVisit(requestParameters: CreateVisitRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createVisitRaw(requestParameters, initOverrides);
    }

    /**
     * Add a wallet account to a user\'s existing Turnkey HD wallet
     * Add a wallet account to a user\'s existing Turnkey HD wallet
     */
    async createWalletAccountRaw(requestParameters: CreateWalletAccountOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createWalletAccount.');
        }

        if (requestParameters.createWalletAccountRequest === null || requestParameters.createWalletAccountRequest === undefined) {
            throw new runtime.RequiredError('createWalletAccountRequest','Required parameter requestParameters.createWalletAccountRequest was null or undefined when calling createWalletAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/walletAccounts`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWalletAccountRequestToJSON(requestParameters.createWalletAccountRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Add a wallet account to a user\'s existing Turnkey HD wallet
     * Add a wallet account to a user\'s existing Turnkey HD wallet
     */
    async createWalletAccount(requestParameters: CreateWalletAccountOperationRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.createWalletAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the embedded wallets for a user
     * Deletes the embedded wallets for a user
     */
    async deleteEmbeddedWalletsRaw(requestParameters: DeleteEmbeddedWalletsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling deleteEmbeddedWallets.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteEmbeddedWalletsRequestToJSON(requestParameters.deleteEmbeddedWalletsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the embedded wallets for a user
     * Deletes the embedded wallets for a user
     */
    async deleteEmbeddedWallets(requestParameters: DeleteEmbeddedWalletsOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteEmbeddedWalletsRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a device (if not default device)
     */
    async deleteMfaDeviceRaw(requestParameters: DeleteMfaDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling deleteMfaDevice.');
        }

        if (requestParameters.mfaDeviceId === null || requestParameters.mfaDeviceId === undefined) {
            throw new runtime.RequiredError('mfaDeviceId','Required parameter requestParameters.mfaDeviceId was null or undefined when calling deleteMfaDevice.');
        }

        if (requestParameters.xMfaAuthToken === null || requestParameters.xMfaAuthToken === undefined) {
            throw new runtime.RequiredError('xMfaAuthToken','Required parameter requestParameters.xMfaAuthToken was null or undefined when calling deleteMfaDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xMfaAuthToken !== undefined && requestParameters.xMfaAuthToken !== null) {
            headerParameters['x-mfa-auth-token'] = String(requestParameters.xMfaAuthToken);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/{mfaDeviceId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"mfaDeviceId"}}`, encodeURIComponent(String(requestParameters.mfaDeviceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a device (if not default device)
     */
    async deleteMfaDevice(requestParameters: DeleteMfaDeviceRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteMfaDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async emailAuthOptionsRaw(requestParameters: EmailAuthOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling emailAuthOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/emailAuth`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async emailAuthOptions(requestParameters: EmailAuthOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.emailAuthOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async emailProviderOptionsRaw(requestParameters: EmailProviderOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling emailProviderOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/emailProvider`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async emailProviderOptions(requestParameters: EmailProviderOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.emailProviderOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async embeddedWalletDeleteRequestOptionsRaw(requestParameters: EmbeddedWalletDeleteRequestOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling embeddedWalletDeleteRequestOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/deleteRequest`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async embeddedWalletDeleteRequestOptions(requestParameters: EmbeddedWalletDeleteRequestOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.embeddedWalletDeleteRequestOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Requests an export of an embedded wallet
     * Requests an export of an embedded wallet
     */
    async embeddedWalletExportRaw(requestParameters: EmbeddedWalletExportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExportEmbeddedWalletResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling embeddedWalletExport.');
        }

        if (requestParameters.walletId === null || requestParameters.walletId === undefined) {
            throw new runtime.RequiredError('walletId','Required parameter requestParameters.walletId was null or undefined when calling embeddedWalletExport.');
        }

        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling embeddedWalletExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/{walletId}/activities/{activityId}/export`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"walletId"}}`, encodeURIComponent(String(requestParameters.walletId))).replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportEmbeddedWalletResponseFromJSON(jsonValue));
    }

    /**
     * Requests an export of an embedded wallet
     * Requests an export of an embedded wallet
     */
    async embeddedWalletExport(requestParameters: EmbeddedWalletExportRequest, initOverrides?: RequestInit): Promise<ExportEmbeddedWalletResponse> {
        const response = await this.embeddedWalletExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async embeddedWalletExportOptionsRaw(requestParameters: EmbeddedWalletExportOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling embeddedWalletExportOptions.');
        }

        if (requestParameters.walletId === null || requestParameters.walletId === undefined) {
            throw new runtime.RequiredError('walletId','Required parameter requestParameters.walletId was null or undefined when calling embeddedWalletExportOptions.');
        }

        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling embeddedWalletExportOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/{walletId}/activities/{activityId}/export`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"walletId"}}`, encodeURIComponent(String(requestParameters.walletId))).replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async embeddedWalletExportOptions(requestParameters: EmbeddedWalletExportOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.embeddedWalletExportOptionsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async eventsOptionsRaw(requestParameters: EventsOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling eventsOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/events`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async eventsOptions(requestParameters: EventsOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.eventsOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint to sign-in using a token issued by an external auth provider
     */
    async externalAuthSigninRaw(requestParameters: ExternalAuthSigninOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling externalAuthSignin.');
        }

        if (requestParameters.externalAuthSigninRequest === null || requestParameters.externalAuthSigninRequest === undefined) {
            throw new runtime.RequiredError('externalAuthSigninRequest','Required parameter requestParameters.externalAuthSigninRequest was null or undefined when calling externalAuthSignin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/externalAuth/signin`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalAuthSigninRequestToJSON(requestParameters.externalAuthSigninRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint to sign-in using a token issued by an external auth provider
     */
    async externalAuthSignin(requestParameters: ExternalAuthSigninOperationRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.externalAuthSigninRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async externalAuthSigninOptionsRaw(requestParameters: ExternalAuthSigninOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling externalAuthSigninOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/externalAuth/signin`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async externalAuthSigninOptions(requestParameters: ExternalAuthSigninOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.externalAuthSigninOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint to verify using a token issued by an external auth provider
     */
    async externalAuthVerifyRaw(requestParameters: ExternalAuthVerifyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling externalAuthVerify.');
        }

        if (requestParameters.externalAuthSigninRequest === null || requestParameters.externalAuthSigninRequest === undefined) {
            throw new runtime.RequiredError('externalAuthSigninRequest','Required parameter requestParameters.externalAuthSigninRequest was null or undefined when calling externalAuthVerify.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/externalAuth/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalAuthSigninRequestToJSON(requestParameters.externalAuthSigninRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint to verify using a token issued by an external auth provider
     */
    async externalAuthVerify(requestParameters: ExternalAuthVerifyRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.externalAuthVerifyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async externalAuthVerifyOptionsRaw(requestParameters: ExternalAuthVerifyOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling externalAuthVerifyOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/externalAuth/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async externalAuthVerifyOptions(requestParameters: ExternalAuthVerifyOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.externalAuthVerifyOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Farcaster SignIn endpoint to exchange SIWF data
     * Farcaster provider SignIn endpoint
     */
    async farcasterSignInRaw(requestParameters: FarcasterSignInOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling farcasterSignIn.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/farcaster/signin`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FarcasterSignInRequestToJSON(requestParameters.farcasterSignInRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Farcaster SignIn endpoint to exchange SIWF data
     * Farcaster provider SignIn endpoint
     */
    async farcasterSignIn(requestParameters: FarcasterSignInOperationRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.farcasterSignInRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async farcasterSignInOptionsRaw(requestParameters: FarcasterSignInOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling farcasterSignInOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/farcaster/signin`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async farcasterSignInOptions(requestParameters: FarcasterSignInOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.farcasterSignInOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Farcaster Verify endpoint to exchange SIWF data
     * Farcaster provider Verify endpoint
     */
    async farcasterVerifyRaw(requestParameters: FarcasterVerifyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling farcasterVerify.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/farcaster/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FarcasterSignInRequestToJSON(requestParameters.farcasterSignInRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Farcaster Verify endpoint to exchange SIWF data
     * Farcaster provider Verify endpoint
     */
    async farcasterVerify(requestParameters: FarcasterVerifyRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.farcasterVerifyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async farcasterVerifyOptionsRaw(requestParameters: FarcasterVerifyOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling farcasterVerifyOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/farcaster/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async farcasterVerifyOptions(requestParameters: FarcasterVerifyOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.farcasterVerifyOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Get the token balances for an account
     */
    async getAccountBalancesRaw(requestParameters: GetAccountBalancesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TokenBalance>>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getAccountBalances.');
        }

        if (requestParameters.chainName === null || requestParameters.chainName === undefined) {
            throw new runtime.RequiredError('chainName','Required parameter requestParameters.chainName was null or undefined when calling getAccountBalances.');
        }

        if (requestParameters.accountAddress === null || requestParameters.accountAddress === undefined) {
            throw new runtime.RequiredError('accountAddress','Required parameter requestParameters.accountAddress was null or undefined when calling getAccountBalances.');
        }

        const queryParameters: any = {};

        if (requestParameters.networkId !== undefined) {
            queryParameters['networkId'] = requestParameters.networkId;
        }

        if (requestParameters.accountAddress !== undefined) {
            queryParameters['accountAddress'] = requestParameters.accountAddress;
        }

        if (requestParameters.includePrices !== undefined) {
            queryParameters['includePrices'] = requestParameters.includePrices;
        }

        if (requestParameters.includeNative !== undefined) {
            queryParameters['includeNative'] = requestParameters.includeNative;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/chains/{chainName}/balances`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"chainName"}}`, encodeURIComponent(String(requestParameters.chainName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TokenBalanceFromJSON));
    }

    /**
     * Get the token balances for an account
     */
    async getAccountBalances(requestParameters: GetAccountBalancesRequest, initOverrides?: RequestInit): Promise<Array<TokenBalance>> {
        const response = await this.getAccountBalancesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async getAccountBalancesOptionsRaw(requestParameters: GetAccountBalancesOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getAccountBalancesOptions.');
        }

        if (requestParameters.chainName === null || requestParameters.chainName === undefined) {
            throw new runtime.RequiredError('chainName','Required parameter requestParameters.chainName was null or undefined when calling getAccountBalancesOptions.');
        }

        if (requestParameters.networkId === null || requestParameters.networkId === undefined) {
            throw new runtime.RequiredError('networkId','Required parameter requestParameters.networkId was null or undefined when calling getAccountBalancesOptions.');
        }

        if (requestParameters.accountAddress === null || requestParameters.accountAddress === undefined) {
            throw new runtime.RequiredError('accountAddress','Required parameter requestParameters.accountAddress was null or undefined when calling getAccountBalancesOptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.networkId !== undefined) {
            queryParameters['networkId'] = requestParameters.networkId;
        }

        if (requestParameters.accountAddress !== undefined) {
            queryParameters['accountAddress'] = requestParameters.accountAddress;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/chains/{chainName}/balances`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"chainName"}}`, encodeURIComponent(String(requestParameters.chainName))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async getAccountBalancesOptions(requestParameters: GetAccountBalancesOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.getAccountBalancesOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Get auth token for given embedded wallet type
     */
    async getAuthTokenRaw(requestParameters: GetAuthTokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmbeddedWalletAuthToken>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getAuthToken.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/authToken`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmbeddedWalletAuthTokenFromJSON(jsonValue));
    }

    /**
     * Get auth token for given embedded wallet type
     */
    async getAuthToken(requestParameters: GetAuthTokenRequest, initOverrides?: RequestInit): Promise<EmbeddedWalletAuthToken> {
        const response = await this.getAuthTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async getAuthTokenOptsRaw(requestParameters: GetAuthTokenOptsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getAuthTokenOpts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/authToken`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async getAuthTokenOpts(requestParameters: GetAuthTokenOptsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.getAuthTokenOptsRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch user profile for the current authenticated user
     */
    async getAuthenticatedUserRaw(requestParameters: GetAuthenticatedUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SdkUser>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getAuthenticatedUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SdkUserFromJSON(jsonValue));
    }

    /**
     * Fetch user profile for the current authenticated user
     */
    async getAuthenticatedUser(requestParameters: GetAuthenticatedUserRequest, initOverrides?: RequestInit): Promise<SdkUser> {
        const response = await this.getAuthenticatedUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates the turnkey request body for a user\'s create wallet account request
     * Generates the turnkey request body for a user\'s create wallet account request
     */
    async getCreateWalletAccountRequestRaw(requestParameters: GetCreateWalletAccountRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TurnkeyCreateWalletAccountsRequestBody>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getCreateWalletAccountRequest.');
        }

        if (requestParameters.chain === null || requestParameters.chain === undefined) {
            throw new runtime.RequiredError('chain','Required parameter requestParameters.chain was null or undefined when calling getCreateWalletAccountRequest.');
        }

        const queryParameters: any = {};

        if (requestParameters.chain !== undefined) {
            queryParameters['chain'] = requestParameters.chain;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/walletAccounts/createRequest`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TurnkeyCreateWalletAccountsRequestBodyFromJSON(jsonValue));
    }

    /**
     * Generates the turnkey request body for a user\'s create wallet account request
     * Generates the turnkey request body for a user\'s create wallet account request
     */
    async getCreateWalletAccountRequest(requestParameters: GetCreateWalletAccountRequestRequest, initOverrides?: RequestInit): Promise<TurnkeyCreateWalletAccountsRequestBody> {
        const response = await this.getCreateWalletAccountRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return the email provider to be used for signing in with a given email. If an external provider such as magicLink is disabled, this endpoint would return emailOnly. If an external provider such as magicLink is enabled, it will return emailOnly when the email already exists in Dynamic and it is associated with a wallet, otherwise it will return magicLink.
     * Get signin email provider
     */
    async getEmailProviderRaw(requestParameters: GetEmailProviderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmailProviderResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEmailProvider.');
        }

        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling getEmailProvider.');
        }

        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/emailProvider`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailProviderResponseFromJSON(jsonValue));
    }

    /**
     * Return the email provider to be used for signing in with a given email. If an external provider such as magicLink is disabled, this endpoint would return emailOnly. If an external provider such as magicLink is enabled, it will return emailOnly when the email already exists in Dynamic and it is associated with a wallet, otherwise it will return magicLink.
     * Get signin email provider
     */
    async getEmailProvider(requestParameters: GetEmailProviderRequest, initOverrides?: RequestInit): Promise<EmailProviderResponse> {
        const response = await this.getEmailProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async getEmbeddedWalletBackupOptionRaw(requestParameters: GetEmbeddedWalletBackupOptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEmbeddedWalletBackupOption.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/backup`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async getEmbeddedWalletBackupOption(requestParameters: GetEmbeddedWalletBackupOptionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.getEmbeddedWalletBackupOptionRaw(requestParameters, initOverrides);
    }

    /**
     * Return the backup key for the embedded wallet of the authenticated user
     */
    async getEmbeddedWalletBackupsRaw(requestParameters: GetEmbeddedWalletBackupsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmbeddedWalletSecret>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEmbeddedWalletBackups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/backup`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmbeddedWalletSecretFromJSON(jsonValue));
    }

    /**
     * Return the backup key for the embedded wallet of the authenticated user
     */
    async getEmbeddedWalletBackups(requestParameters: GetEmbeddedWalletBackupsRequest, initOverrides?: RequestInit): Promise<EmbeddedWalletSecret> {
        const response = await this.getEmbeddedWalletBackupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return the passcode for the pregenerated embedded wallet of the authenticated user
     */
    async getEmbeddedWalletPasscodeRaw(requestParameters: GetEmbeddedWalletPasscodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmbeddedWalletSecret>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEmbeddedWalletPasscode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/passcode`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmbeddedWalletSecretFromJSON(jsonValue));
    }

    /**
     * Return the passcode for the pregenerated embedded wallet of the authenticated user
     */
    async getEmbeddedWalletPasscode(requestParameters: GetEmbeddedWalletPasscodeRequest, initOverrides?: RequestInit): Promise<EmbeddedWalletSecret> {
        const response = await this.getEmbeddedWalletPasscodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async getEmbeddedWalletPasscodeOptionRaw(requestParameters: GetEmbeddedWalletPasscodeOptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEmbeddedWalletPasscodeOption.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/passcode`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async getEmbeddedWalletPasscodeOption(requestParameters: GetEmbeddedWalletPasscodeOptionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.getEmbeddedWalletPasscodeOptionRaw(requestParameters, initOverrides);
    }

    /**
     * Generates the turnkey request body for a user\'s delete wallets request
     * Generates the turnkey request body for a user\'s delete wallets request
     */
    async getEmbeddedWalletsDeleteRequestRaw(requestParameters: GetEmbeddedWalletsDeleteRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TurnkeyDeleteEmbeddedWalletsRequestBody>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEmbeddedWalletsDeleteRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/deleteRequest`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TurnkeyDeleteEmbeddedWalletsRequestBodyFromJSON(jsonValue));
    }

    /**
     * Generates the turnkey request body for a user\'s delete wallets request
     * Generates the turnkey request body for a user\'s delete wallets request
     */
    async getEmbeddedWalletsDeleteRequest(requestParameters: GetEmbeddedWalletsDeleteRequestRequest, initOverrides?: RequestInit): Promise<TurnkeyDeleteEmbeddedWalletsRequestBody> {
        const response = await this.getEmbeddedWalletsDeleteRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the environment\'s settings
     */
    async getEnvironmentSettingsRaw(requestParameters: GetEnvironmentSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectSettings>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEnvironmentSettings.');
        }

        const queryParameters: any = {};

        if (requestParameters.sdkVersion !== undefined) {
            queryParameters['sdkVersion'] = requestParameters.sdkVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/settings`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectSettingsFromJSON(jsonValue));
    }

    /**
     * Get the environment\'s settings
     */
    async getEnvironmentSettings(requestParameters: GetEnvironmentSettingsRequest, initOverrides?: RequestInit): Promise<ProjectSettings> {
        const response = await this.getEnvironmentSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exchange rates for the given currency
     */
    async getExchangeRatesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Currency>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/exchangeRates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CurrencyFromJSON));
    }

    /**
     * Exchange rates for the given currency
     */
    async getExchangeRates(initOverrides?: RequestInit): Promise<Array<Currency>> {
        const response = await this.getExchangeRatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async getExchangeRatesOptionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/exchangeRates`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async getExchangeRatesOptions(initOverrides?: RequestInit): Promise<void> {
        await this.getExchangeRatesOptionsRaw(initOverrides);
    }

    /**
     * Health check endpoint to check for uptime of API.
     */
    async getHealthcheckRaw(requestParameters: GetHealthcheckRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HealthcheckResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getHealthcheck.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/healthcheck`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthcheckResponseFromJSON(jsonValue));
    }

    /**
     * Health check endpoint to check for uptime of API.
     */
    async getHealthcheck(requestParameters: GetHealthcheckRequest, initOverrides?: RequestInit): Promise<HealthcheckResponse> {
        const response = await this.getHealthcheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async getHealthcheckOptionsRaw(requestParameters: GetHealthcheckOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getHealthcheckOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/healthcheck`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async getHealthcheckOptions(requestParameters: GetHealthcheckOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.getHealthcheckOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Find jwks for public key
     */
    async getJwksByEnvironmentIdRaw(requestParameters: GetJwksByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JwksResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getJwksByEnvironmentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/.well-known/jwks`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JwksResponseFromJSON(jsonValue));
    }

    /**
     * Find jwks for public key
     */
    async getJwksByEnvironmentId(requestParameters: GetJwksByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<JwksResponse> {
        const response = await this.getJwksByEnvironmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the current user\'s MFA by device id
     */
    async getMfaDeviceRaw(requestParameters: GetMfaDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MFADevice>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getMfaDevice.');
        }

        if (requestParameters.mfaDeviceId === null || requestParameters.mfaDeviceId === undefined) {
            throw new runtime.RequiredError('mfaDeviceId','Required parameter requestParameters.mfaDeviceId was null or undefined when calling getMfaDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/{mfaDeviceId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"mfaDeviceId"}}`, encodeURIComponent(String(requestParameters.mfaDeviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MFADeviceFromJSON(jsonValue));
    }

    /**
     * Get the current user\'s MFA by device id
     */
    async getMfaDevice(requestParameters: GetMfaDeviceRequest, initOverrides?: RequestInit): Promise<MFADevice> {
        const response = await this.getMfaDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async getMfaDeviceOptionsRaw(requestParameters: GetMfaDeviceOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getMfaDeviceOptions.');
        }

        if (requestParameters.mfaDeviceId === null || requestParameters.mfaDeviceId === undefined) {
            throw new runtime.RequiredError('mfaDeviceId','Required parameter requestParameters.mfaDeviceId was null or undefined when calling getMfaDeviceOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/{mfaDeviceId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"mfaDeviceId"}}`, encodeURIComponent(String(requestParameters.mfaDeviceId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async getMfaDeviceOptions(requestParameters: GetMfaDeviceOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.getMfaDeviceOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Find the configuration for the enabled networks associated to an environment
     */
    async getNetworksConfigurationByEnvIdRaw(requestParameters: GetNetworksConfigurationByEnvIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NetworkConfigurationResponse>>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getNetworksConfigurationByEnvId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/networks`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NetworkConfigurationResponseFromJSON));
    }

    /**
     * Find the configuration for the enabled networks associated to an environment
     */
    async getNetworksConfigurationByEnvId(requestParameters: GetNetworksConfigurationByEnvIdRequest, initOverrides?: RequestInit): Promise<Array<NetworkConfigurationResponse>> {
        const response = await this.getNetworksConfigurationByEnvIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create nonce for authentication flow
     */
    async getNonceRaw(requestParameters: GetNonceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NonceResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getNonce.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/nonce`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NonceResponseFromJSON(jsonValue));
    }

    /**
     * Create nonce for authentication flow
     */
    async getNonce(requestParameters: GetNonceRequest, initOverrides?: RequestInit): Promise<NonceResponse> {
        const response = await this.getNonceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get recovery codes
     */
    async getRecoveryCodesRaw(requestParameters: GetRecoveryCodesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MFAGetRecoveryCodesResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getRecoveryCodes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/recovery`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MFAGetRecoveryCodesResponseFromJSON(jsonValue));
    }

    /**
     * Get recovery codes
     */
    async getRecoveryCodes(requestParameters: GetRecoveryCodesRequest, initOverrides?: RequestInit): Promise<MFAGetRecoveryCodesResponse> {
        const response = await this.getRecoveryCodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find the configuration for the enabled onramps associated to an environment
     * Configuration for enabled onramps
     */
    async getSupportedOnrampsRaw(requestParameters: GetSupportedOnrampsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SupportedOnrampsResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getSupportedOnramps.');
        }

        if (requestParameters.walletAddress === null || requestParameters.walletAddress === undefined) {
            throw new runtime.RequiredError('walletAddress','Required parameter requestParameters.walletAddress was null or undefined when calling getSupportedOnramps.');
        }

        if (requestParameters.chain === null || requestParameters.chain === undefined) {
            throw new runtime.RequiredError('chain','Required parameter requestParameters.chain was null or undefined when calling getSupportedOnramps.');
        }

        const queryParameters: any = {};

        if (requestParameters.walletAddress !== undefined) {
            queryParameters['walletAddress'] = requestParameters.walletAddress;
        }

        if (requestParameters.chain !== undefined) {
            queryParameters['chain'] = requestParameters.chain;
        }

        if (requestParameters.networkId !== undefined) {
            queryParameters['networkId'] = requestParameters.networkId;
        }

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/onramps`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupportedOnrampsResponseFromJSON(jsonValue));
    }

    /**
     * Find the configuration for the enabled onramps associated to an environment
     * Configuration for enabled onramps
     */
    async getSupportedOnramps(requestParameters: GetSupportedOnrampsRequest, initOverrides?: RequestInit): Promise<SupportedOnrampsResponse> {
        const response = await this.getSupportedOnrampsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get HTML with rendered Telegram login widget
     */
    async getTelegramAuthRaw(requestParameters: GetTelegramAuthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getTelegramAuth.');
        }

        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling getTelegramAuth.');
        }

        const queryParameters: any = {};

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/telegram/auth`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get HTML with rendered Telegram login widget
     */
    async getTelegramAuth(requestParameters: GetTelegramAuthRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.getTelegramAuthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the current user\'s MFA Devices
     */
    async getUserMfaDevicesRaw(requestParameters: GetUserMfaDevicesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MFAListDevicesResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getUserMfaDevices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MFAListDevicesResponseFromJSON(jsonValue));
    }

    /**
     * Get the current user\'s MFA Devices
     */
    async getUserMfaDevices(requestParameters: GetUserMfaDevicesRequest, initOverrides?: RequestInit): Promise<MFAListDevicesResponse> {
        const response = await this.getUserMfaDevicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets passkey data associated with a user
     */
    async getUserPasskeysRaw(requestParameters: GetUserPasskeysRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetUserPasskeysResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getUserPasskeys.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/passkeys`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserPasskeysResponseFromJSON(jsonValue));
    }

    /**
     * Gets passkey data associated with a user
     */
    async getUserPasskeys(requestParameters: GetUserPasskeysRequest, initOverrides?: RequestInit): Promise<GetUserPasskeysResponse> {
        const response = await this.getUserPasskeysRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint initializes a secure oauth authentication sequence by providing the state and current url path to the API.
     * Initialize oauth auth sequence
     */
    async initAuthRaw(requestParameters: InitAuthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling initAuth.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling initAuth.');
        }

        if (requestParameters.oauthInitAuthRequest === null || requestParameters.oauthInitAuthRequest === undefined) {
            throw new runtime.RequiredError('oauthInitAuthRequest','Required parameter requestParameters.oauthInitAuthRequest was null or undefined when calling initAuth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/initAuth`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OauthInitAuthRequestToJSON(requestParameters.oauthInitAuthRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint initializes a secure oauth authentication sequence by providing the state and current url path to the API.
     * Initialize oauth auth sequence
     */
    async initAuth(requestParameters: InitAuthRequest, initOverrides?: RequestInit): Promise<void> {
        await this.initAuthRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async initAuthOptionsRaw(requestParameters: InitAuthOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling initAuthOptions.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling initAuthOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/initAuth`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async initAuthOptions(requestParameters: InitAuthOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.initAuthOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Initialize the email authentication process for a user\'s embedded wallet
     * Initialize the email authentication process for a user\'s embedded wallet
     */
    async initEmailAuthRaw(requestParameters: InitEmailAuthOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InitEmailAuthResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling initEmailAuth.');
        }

        if (requestParameters.initEmailAuthRequest === null || requestParameters.initEmailAuthRequest === undefined) {
            throw new runtime.RequiredError('initEmailAuthRequest','Required parameter requestParameters.initEmailAuthRequest was null or undefined when calling initEmailAuth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/emailAuth`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InitEmailAuthRequestToJSON(requestParameters.initEmailAuthRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InitEmailAuthResponseFromJSON(jsonValue));
    }

    /**
     * Initialize the email authentication process for a user\'s embedded wallet
     * Initialize the email authentication process for a user\'s embedded wallet
     */
    async initEmailAuth(requestParameters: InitEmailAuthOperationRequest, initOverrides?: RequestInit): Promise<InitEmailAuthResponse> {
        const response = await this.initEmailAuthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initialize the passkey recovery process for a user\'s passkey embedded wallet
     * Initialize the passkey recovery process for a user\'s passkey embedded wallet
     */
    async initPasskeyRecoveryRaw(requestParameters: InitPasskeyRecoveryOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InitPasskeyRecoveryResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling initPasskeyRecovery.');
        }

        if (requestParameters.initPasskeyRecoveryRequest === null || requestParameters.initPasskeyRecoveryRequest === undefined) {
            throw new runtime.RequiredError('initPasskeyRecoveryRequest','Required parameter requestParameters.initPasskeyRecoveryRequest was null or undefined when calling initPasskeyRecovery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/passkeyRecovery`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InitPasskeyRecoveryRequestToJSON(requestParameters.initPasskeyRecoveryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InitPasskeyRecoveryResponseFromJSON(jsonValue));
    }

    /**
     * Initialize the passkey recovery process for a user\'s passkey embedded wallet
     * Initialize the passkey recovery process for a user\'s passkey embedded wallet
     */
    async initPasskeyRecovery(requestParameters: InitPasskeyRecoveryOperationRequest, initOverrides?: RequestInit): Promise<InitPasskeyRecoveryResponse> {
        const response = await this.initPasskeyRecoveryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async jwksOptionsRaw(requestParameters: JwksOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling jwksOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/.well-known/jwks`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async jwksOptions(requestParameters: JwksOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.jwksOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async listMfaDevicesOptionsRaw(requestParameters: ListMfaDevicesOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling listMfaDevicesOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async listMfaDevicesOptions(requestParameters: ListMfaDevicesOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.listMfaDevicesOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Update SDK settings for a specific environment
     */
    async logDynamicSdkSettingsRaw(requestParameters: LogDynamicSdkSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling logDynamicSdkSettings.');
        }

        if (requestParameters.sdkSettingsRequest === null || requestParameters.sdkSettingsRequest === undefined) {
            throw new runtime.RequiredError('sdkSettingsRequest','Required parameter requestParameters.sdkSettingsRequest was null or undefined when calling logDynamicSdkSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/sdkSettings`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SdkSettingsRequestToJSON(requestParameters.sdkSettingsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update SDK settings for a specific environment
     */
    async logDynamicSdkSettings(requestParameters: LogDynamicSdkSettingsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.logDynamicSdkSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * Used to merge two owned accounts connected by email address
     * After successful verification allow to merge two owned accounts
     */
    async mergeUsersRaw(requestParameters: MergeUsersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling mergeUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/merge`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Used to merge two owned accounts connected by email address
     * After successful verification allow to merge two owned accounts
     */
    async mergeUsers(requestParameters: MergeUsersRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.mergeUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async mergeUsersOptionsRaw(requestParameters: MergeUsersOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling mergeUsersOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/merge`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async mergeUsersOptions(requestParameters: MergeUsersOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.mergeUsersOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async networksOptionsRaw(requestParameters: NetworksOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling networksOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/networks`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async networksOptions(requestParameters: NetworksOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.networksOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async nonceOptionsRaw(requestParameters: NonceOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling nonceOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/nonce`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async nonceOptions(requestParameters: NonceOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.nonceOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint where an oauth provider would return authorization HTML used for mobile-friendly login, such as Apple ID with Touch ID on enabled devices.
     * Oauth provider authorization endpoint
     */
    async oauthAuthorizeHtmlRaw(requestParameters: OauthAuthorizeHtmlRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthAuthorizeHtml.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthAuthorizeHtml.');
        }

        const queryParameters: any = {};

        if (requestParameters.scope !== undefined) {
            queryParameters['scope'] = requestParameters.scope;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.redirectUri !== undefined) {
            queryParameters['redirect_uri'] = requestParameters.redirectUri;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/authorizeHtml`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Endpoint where an oauth provider would return authorization HTML used for mobile-friendly login, such as Apple ID with Touch ID on enabled devices.
     * Oauth provider authorization endpoint
     */
    async oauthAuthorizeHtml(requestParameters: OauthAuthorizeHtmlRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.oauthAuthorizeHtmlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async oauthAuthorizeHtmlOptionsRaw(requestParameters: OauthAuthorizeHtmlOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthAuthorizeHtmlOptions.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthAuthorizeHtmlOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/authorizeHtml`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async oauthAuthorizeHtmlOptions(requestParameters: OauthAuthorizeHtmlOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.oauthAuthorizeHtmlOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Get redirect URL for Oauth provider login
     */
    async oauthLoginRaw(requestParameters: OauthLoginRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthLogin.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/login`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get redirect URL for Oauth provider login
     */
    async oauthLogin(requestParameters: OauthLoginRequest, initOverrides?: RequestInit): Promise<void> {
        await this.oauthLoginRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async oauthLoginOptionsRaw(requestParameters: OauthLoginOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthLoginOptions.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthLoginOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/login`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async oauthLoginOptions(requestParameters: OauthLoginOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.oauthLoginOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Get redirect URL for Oauth provider login. This will return a string that the SDK can manipulate before redirecting the browser to.
     */
    async oauthLoginUrlRaw(requestParameters: OauthLoginUrlRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OauthProviderLoginUrl>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthLoginUrl.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthLoginUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/loginUrl`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OauthProviderLoginUrlFromJSON(jsonValue));
    }

    /**
     * Get redirect URL for Oauth provider login. This will return a string that the SDK can manipulate before redirecting the browser to.
     */
    async oauthLoginUrl(requestParameters: OauthLoginUrlRequest, initOverrides?: RequestInit): Promise<OauthProviderLoginUrl> {
        const response = await this.oauthLoginUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async oauthLoginUrlOptionsRaw(requestParameters: OauthLoginUrlOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthLoginUrlOptions.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthLoginUrlOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/loginUrl`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async oauthLoginUrlOptions(requestParameters: OauthLoginUrlOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.oauthLoginUrlOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint where an oauth provider would redirect after a successful user authorizing the oauth application.
     * Oauth provider redirect endpoint for apple ID
     */
    async oauthRedirectAppleRaw(requestParameters: OauthRedirectAppleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthRedirectApple.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.code !== undefined) {
            formParams.append('code', requestParameters.code as any);
        }

        if (requestParameters.state !== undefined) {
            formParams.append('state', requestParameters.state as any);
        }

        if (requestParameters.idToken !== undefined) {
            formParams.append('id_token', requestParameters.idToken as any);
        }

        if (requestParameters.error !== undefined) {
            formParams.append('error', requestParameters.error as any);
        }

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/apple/redirect`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Endpoint where an oauth provider would redirect after a successful user authorizing the oauth application.
     * Oauth provider redirect endpoint for apple ID
     */
    async oauthRedirectApple(requestParameters: OauthRedirectAppleRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.oauthRedirectAppleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async oauthRedirectAppleOptionsRaw(requestParameters: OauthRedirectAppleOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthRedirectAppleOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/apple/redirect`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async oauthRedirectAppleOptions(requestParameters: OauthRedirectAppleOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.oauthRedirectAppleOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint where an oauth provider would redirect after a successful user authorizing the oauth application.
     * Oauth provider redirect endpoint
     */
    async oauthRedirectCodeRaw(requestParameters: OauthRedirectCodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthRedirectCode.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthRedirectCode.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.scope !== undefined) {
            queryParameters['scope'] = requestParameters.scope;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.authuser !== undefined) {
            queryParameters['authuser'] = requestParameters.authuser;
        }

        if (requestParameters.prompt !== undefined) {
            queryParameters['prompt'] = requestParameters.prompt;
        }

        if (requestParameters.error !== undefined) {
            queryParameters['error'] = requestParameters.error;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/redirect`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Endpoint where an oauth provider would redirect after a successful user authorizing the oauth application.
     * Oauth provider redirect endpoint
     */
    async oauthRedirectCode(requestParameters: OauthRedirectCodeRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.oauthRedirectCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async oauthRedirectOptionsRaw(requestParameters: OauthRedirectOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthRedirectOptions.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthRedirectOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/redirect`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async oauthRedirectOptions(requestParameters: OauthRedirectOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.oauthRedirectOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Returns the authorization code or error retuned by oauth provider
     */
    async oauthResultRaw(requestParameters: OauthResultOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OauthResultResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthResult.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthResult.');
        }

        if (requestParameters.oauthResultRequest === null || requestParameters.oauthResultRequest === undefined) {
            throw new runtime.RequiredError('oauthResultRequest','Required parameter requestParameters.oauthResultRequest was null or undefined when calling oauthResult.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/oauthResult`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OauthResultRequestToJSON(requestParameters.oauthResultRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OauthResultResponseFromJSON(jsonValue));
    }

    /**
     * Returns the authorization code or error retuned by oauth provider
     */
    async oauthResult(requestParameters: OauthResultOperationRequest, initOverrides?: RequestInit): Promise<OauthResultResponse> {
        const response = await this.oauthResultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async oauthResultOptionsRaw(requestParameters: OauthResultOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthResultOptions.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthResultOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/oauthResult`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async oauthResultOptions(requestParameters: OauthResultOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.oauthResultOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Standard OAuth SignIn callback to exchange temproary code for oauth access and return a valid Dynamic JWT and user
     * Oauth provider SignIn endpoint
     */
    async oauthSignInRaw(requestParameters: OauthSignInRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthSignIn.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthSignIn.');
        }

        if (requestParameters.oauthRequest === null || requestParameters.oauthRequest === undefined) {
            throw new runtime.RequiredError('oauthRequest','Required parameter requestParameters.oauthRequest was null or undefined when calling oauthSignIn.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/signIn`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OauthRequestToJSON(requestParameters.oauthRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Standard OAuth SignIn callback to exchange temproary code for oauth access and return a valid Dynamic JWT and user
     * Oauth provider SignIn endpoint
     */
    async oauthSignIn(requestParameters: OauthSignInRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.oauthSignInRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async oauthSignInOptionsRaw(requestParameters: OauthSignInOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthSignInOptions.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthSignInOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/signIn`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async oauthSignInOptions(requestParameters: OauthSignInOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.oauthSignInOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Standard OAuth verify callback to exchange temproary code for oauth access
     * Oauth provider verify endpoint
     */
    async oauthVerifyRaw(requestParameters: OauthVerifyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthVerify.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthVerify.');
        }

        if (requestParameters.oauthRequest === null || requestParameters.oauthRequest === undefined) {
            throw new runtime.RequiredError('oauthRequest','Required parameter requestParameters.oauthRequest was null or undefined when calling oauthVerify.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OauthRequestToJSON(requestParameters.oauthRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Standard OAuth verify callback to exchange temproary code for oauth access
     * Oauth provider verify endpoint
     */
    async oauthVerify(requestParameters: OauthVerifyRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.oauthVerifyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async oauthVerifyOptionsRaw(requestParameters: OauthVerifyOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling oauthVerifyOptions.');
        }

        if (requestParameters.providerType === null || requestParameters.providerType === undefined) {
            throw new runtime.RequiredError('providerType','Required parameter requestParameters.providerType was null or undefined when calling oauthVerifyOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/providers/{providerType}/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"providerType"}}`, encodeURIComponent(String(requestParameters.providerType))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async oauthVerifyOptions(requestParameters: OauthVerifyOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.oauthVerifyOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async onrampsOptionsRaw(requestParameters: OnrampsOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling onrampsOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/onramps`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async onrampsOptions(requestParameters: OnrampsOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.onrampsOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Add fees to a Solana transaction
     */
    async optimizeTransactionRaw(requestParameters: OptimizeTransactionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SolanaTransactionOptimizationResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling optimizeTransaction.');
        }

        if (requestParameters.solanaTransactionOptimizationRequest === null || requestParameters.solanaTransactionOptimizationRequest === undefined) {
            throw new runtime.RequiredError('solanaTransactionOptimizationRequest','Required parameter requestParameters.solanaTransactionOptimizationRequest was null or undefined when calling optimizeTransaction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/solana/optimizeTransaction`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SolanaTransactionOptimizationRequestToJSON(requestParameters.solanaTransactionOptimizationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SolanaTransactionOptimizationResponseFromJSON(jsonValue));
    }

    /**
     * Add fees to a Solana transaction
     */
    async optimizeTransaction(requestParameters: OptimizeTransactionRequest, initOverrides?: RequestInit): Promise<SolanaTransactionOptimizationResponse> {
        const response = await this.optimizeTransactionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async optimizeTransactionOptionsRaw(requestParameters: OptimizeTransactionOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling optimizeTransactionOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/solana/optimizeTransaction`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async optimizeTransactionOptions(requestParameters: OptimizeTransactionOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.optimizeTransactionOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async optionsConnectRaw(requestParameters: OptionsConnectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling optionsConnect.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/connect`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async optionsConnect(requestParameters: OptionsConnectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.optionsConnectRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async passkeyRecoveryOptionsRaw(requestParameters: PasskeyRecoveryOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling passkeyRecoveryOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/passkeyRecovery`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async passkeyRecoveryOptions(requestParameters: PasskeyRecoveryOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.passkeyRecoveryOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Telegram authentication
     */
    async postTelegramAuthRaw(requestParameters: PostTelegramAuthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling postTelegramAuth.');
        }

        if (requestParameters.telegramPostRequest === null || requestParameters.telegramPostRequest === undefined) {
            throw new runtime.RequiredError('telegramPostRequest','Required parameter requestParameters.telegramPostRequest was null or undefined when calling postTelegramAuth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/telegram/auth`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelegramPostRequestToJSON(requestParameters.telegramPostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Telegram authentication
     */
    async postTelegramAuth(requestParameters: PostTelegramAuthRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postTelegramAuthRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint to send minimal wallet information to the API to prefetch name service information for an address.
     * prefetch information for wallet address
     */
    async prefetchRaw(requestParameters: PrefetchOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling prefetch.');
        }

        if (requestParameters.prefetchRequest === null || requestParameters.prefetchRequest === undefined) {
            throw new runtime.RequiredError('prefetchRequest','Required parameter requestParameters.prefetchRequest was null or undefined when calling prefetch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/prefetch`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrefetchRequestToJSON(requestParameters.prefetchRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint to send minimal wallet information to the API to prefetch name service information for an address.
     * prefetch information for wallet address
     */
    async prefetch(requestParameters: PrefetchOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.prefetchRaw(requestParameters, initOverrides);
    }

    /**
     * Publish events for SDK
     */
    async publishEventRaw(requestParameters: PublishEventRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling publishEvent.');
        }

        if (requestParameters.publishEvents === null || requestParameters.publishEvents === undefined) {
            throw new runtime.RequiredError('publishEvents','Required parameter requestParameters.publishEvents was null or undefined when calling publishEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/events`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PublishEventsToJSON(requestParameters.publishEvents),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Publish events for SDK
     */
    async publishEvent(requestParameters: PublishEventRequest, initOverrides?: RequestInit): Promise<void> {
        await this.publishEventRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async recoveryCodesOptionsRaw(requestParameters: RecoveryCodesOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling recoveryCodesOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/recovery`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async recoveryCodesOptions(requestParameters: RecoveryCodesOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.recoveryCodesOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async recoveryEmailOptionsRaw(requestParameters: RecoveryEmailOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling recoveryEmailOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/recoveryEmail`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async recoveryEmailOptions(requestParameters: RecoveryEmailOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.recoveryEmailOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint to refresh the JWT auth token using the current valid JWT auth token in the authorization header
     */
    async refreshAuthRaw(requestParameters: RefreshAuthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling refreshAuth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/refresh`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint to refresh the JWT auth token using the current valid JWT auth token in the authorization header
     */
    async refreshAuth(requestParameters: RefreshAuthRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.refreshAuthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async refreshOptionsRaw(requestParameters: RefreshOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling refreshOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/refresh`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async refreshOptions(requestParameters: RefreshOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.refreshOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Register a new Passkey MFA device
     */
    async registerPasskeyMfaDeviceRaw(requestParameters: RegisterPasskeyMfaDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MFARegisterPasskeyDeviceGetResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling registerPasskeyMfaDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/register/passkey`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MFARegisterPasskeyDeviceGetResponseFromJSON(jsonValue));
    }

    /**
     * Register a new Passkey MFA device
     */
    async registerPasskeyMfaDevice(requestParameters: RegisterPasskeyMfaDeviceRequest, initOverrides?: RequestInit): Promise<MFARegisterPasskeyDeviceGetResponse> {
        const response = await this.registerPasskeyMfaDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async registerPasskeyMfaDeviceOptionsRaw(requestParameters: RegisterPasskeyMfaDeviceOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling registerPasskeyMfaDeviceOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/register/passkey`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async registerPasskeyMfaDeviceOptions(requestParameters: RegisterPasskeyMfaDeviceOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.registerPasskeyMfaDeviceOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Verify Passkey MFA device
     */
    async registerPasskeyMfaDeviceVerifyRaw(requestParameters: RegisterPasskeyMfaDeviceVerifyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling registerPasskeyMfaDeviceVerify.');
        }

        if (requestParameters.mFARegisterPasskeyDevicePostRequest === null || requestParameters.mFARegisterPasskeyDevicePostRequest === undefined) {
            throw new runtime.RequiredError('mFARegisterPasskeyDevicePostRequest','Required parameter requestParameters.mFARegisterPasskeyDevicePostRequest was null or undefined when calling registerPasskeyMfaDeviceVerify.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/register/passkey`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MFARegisterPasskeyDevicePostRequestToJSON(requestParameters.mFARegisterPasskeyDevicePostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Verify Passkey MFA device
     */
    async registerPasskeyMfaDeviceVerify(requestParameters: RegisterPasskeyMfaDeviceVerifyRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.registerPasskeyMfaDeviceVerifyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Registers a session key for an embedded wallet
     * Registers a session key for an embedded wallet
     */
    async registerSessionKeyRaw(requestParameters: RegisterSessionKeyOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RegisterEmbeddedWalletSessionKeyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling registerSessionKey.');
        }

        if (requestParameters.registerSessionKeyRequest === null || requestParameters.registerSessionKeyRequest === undefined) {
            throw new runtime.RequiredError('registerSessionKeyRequest','Required parameter requestParameters.registerSessionKeyRequest was null or undefined when calling registerSessionKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/sessionKey`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterSessionKeyRequestToJSON(requestParameters.registerSessionKeyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterEmbeddedWalletSessionKeyResponseFromJSON(jsonValue));
    }

    /**
     * Registers a session key for an embedded wallet
     * Registers a session key for an embedded wallet
     */
    async registerSessionKey(requestParameters: RegisterSessionKeyOperationRequest, initOverrides?: RequestInit): Promise<RegisterEmbeddedWalletSessionKeyResponse> {
        const response = await this.registerSessionKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Register a new TOTP MFA device and get Totp Secret
     */
    async registerTotpMfaDeviceRaw(requestParameters: RegisterTotpMfaDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MFARegisterTotpDeviceGetResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling registerTotpMfaDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/register/totp`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MFARegisterTotpDeviceGetResponseFromJSON(jsonValue));
    }

    /**
     * Register a new TOTP MFA device and get Totp Secret
     */
    async registerTotpMfaDevice(requestParameters: RegisterTotpMfaDeviceRequest, initOverrides?: RequestInit): Promise<MFARegisterTotpDeviceGetResponse> {
        const response = await this.registerTotpMfaDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async registerTotpMfaDeviceOptionsRaw(requestParameters: RegisterTotpMfaDeviceOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling registerTotpMfaDeviceOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/register/totp`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async registerTotpMfaDeviceOptions(requestParameters: RegisterTotpMfaDeviceOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.registerTotpMfaDeviceOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Verify TOTP MFA device
     */
    async registerTotpMfaDeviceVerifyRaw(requestParameters: RegisterTotpMfaDeviceVerifyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MFADevice>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling registerTotpMfaDeviceVerify.');
        }

        if (requestParameters.mFARegisterTotpDevicePostRequest === null || requestParameters.mFARegisterTotpDevicePostRequest === undefined) {
            throw new runtime.RequiredError('mFARegisterTotpDevicePostRequest','Required parameter requestParameters.mFARegisterTotpDevicePostRequest was null or undefined when calling registerTotpMfaDeviceVerify.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/register/totp`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MFARegisterTotpDevicePostRequestToJSON(requestParameters.mFARegisterTotpDevicePostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MFADeviceFromJSON(jsonValue));
    }

    /**
     * Verify TOTP MFA device
     */
    async registerTotpMfaDeviceVerify(requestParameters: RegisterTotpMfaDeviceVerifyRequest, initOverrides?: RequestInit): Promise<MFADevice> {
        const response = await this.registerTotpMfaDeviceVerifyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send new code for email verification
     */
    async retryEmailVerificationRaw(requestParameters: RetryEmailVerificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmailVerificationCreateResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling retryEmailVerification.');
        }

        if (requestParameters.emailVerificationRetryRequest === null || requestParameters.emailVerificationRetryRequest === undefined) {
            throw new runtime.RequiredError('emailVerificationRetryRequest','Required parameter requestParameters.emailVerificationRetryRequest was null or undefined when calling retryEmailVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/emailVerifications/retry`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailVerificationRetryRequestToJSON(requestParameters.emailVerificationRetryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailVerificationCreateResponseFromJSON(jsonValue));
    }

    /**
     * Send new code for email verification
     */
    async retryEmailVerification(requestParameters: RetryEmailVerificationRequest, initOverrides?: RequestInit): Promise<EmailVerificationCreateResponse> {
        const response = await this.retryEmailVerificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async retryEmailVerificationOptionsRaw(requestParameters: RetryEmailVerificationOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling retryEmailVerificationOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/emailVerifications/retry`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async retryEmailVerificationOptions(requestParameters: RetryEmailVerificationOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.retryEmailVerificationOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Send new code for sms verification
     */
    async retrySmsVerificationRaw(requestParameters: RetrySmsVerificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SmsVerificationCreateResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling retrySmsVerification.');
        }

        if (requestParameters.smsVerificationRetryRequest === null || requestParameters.smsVerificationRetryRequest === undefined) {
            throw new runtime.RequiredError('smsVerificationRetryRequest','Required parameter requestParameters.smsVerificationRetryRequest was null or undefined when calling retrySmsVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/smsVerifications/retry`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SmsVerificationRetryRequestToJSON(requestParameters.smsVerificationRetryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SmsVerificationCreateResponseFromJSON(jsonValue));
    }

    /**
     * Send new code for sms verification
     */
    async retrySmsVerification(requestParameters: RetrySmsVerificationRequest, initOverrides?: RequestInit): Promise<SmsVerificationCreateResponse> {
        const response = await this.retrySmsVerificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async retrySmsVerificationOptionsRaw(requestParameters: RetrySmsVerificationOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling retrySmsVerificationOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/smsVerifications/retry`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async retrySmsVerificationOptions(requestParameters: RetrySmsVerificationOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.retrySmsVerificationOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Revoke a session
     */
    async revokeSessionRaw(requestParameters: RevokeSessionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling revokeSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/revoke`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Revoke a session
     */
    async revokeSession(requestParameters: RevokeSessionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.revokeSessionRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async revokeSessionOptionRaw(requestParameters: RevokeSessionOptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling revokeSessionOption.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/revoke`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async revokeSessionOption(requestParameters: RevokeSessionOptionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.revokeSessionOptionRaw(requestParameters, initOverrides);
    }

    /**
     * Scan a URL for potential malicious activity
     */
    async scanUrlRaw(requestParameters: ScanUrlRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ScanWebsiteUrlResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling scanUrl.');
        }

        if (requestParameters.scanWebsiteUrlRequest === null || requestParameters.scanWebsiteUrlRequest === undefined) {
            throw new runtime.RequiredError('scanWebsiteUrlRequest','Required parameter requestParameters.scanWebsiteUrlRequest was null or undefined when calling scanUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/scan/websiteUrl`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScanWebsiteUrlRequestToJSON(requestParameters.scanWebsiteUrlRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScanWebsiteUrlResponseFromJSON(jsonValue));
    }

    /**
     * Scan a URL for potential malicious activity
     */
    async scanUrl(requestParameters: ScanUrlRequest, initOverrides?: RequestInit): Promise<ScanWebsiteUrlResponse> {
        const response = await this.scanUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async scanUrlOptionsRaw(requestParameters: ScanUrlOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling scanUrlOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/scan/websiteUrl`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async scanUrlOptions(requestParameters: ScanUrlOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.scanUrlOptionsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sdkSettingsOptionsRaw(requestParameters: SdkSettingsOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling sdkSettingsOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/sdkSettings`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sdkSettingsOptions(requestParameters: SdkSettingsOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.sdkSettingsOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * When a user selects a wallet to be the primary wallet in a multi-wallet enabled environment, this endpoint is called to record this on the backend.
     */
    async selectUserWalletRaw(requestParameters: SelectUserWalletRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling selectUserWallet.');
        }

        if (requestParameters.userWalletSelectionRequest === null || requestParameters.userWalletSelectionRequest === undefined) {
            throw new runtime.RequiredError('userWalletSelectionRequest','Required parameter requestParameters.userWalletSelectionRequest was null or undefined when calling selectUserWallet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/wallets/selection`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserWalletSelectionRequestToJSON(requestParameters.userWalletSelectionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * When a user selects a wallet to be the primary wallet in a multi-wallet enabled environment, this endpoint is called to record this on the backend.
     */
    async selectUserWallet(requestParameters: SelectUserWalletRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.selectUserWalletRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async selectUserWalletOptionsRaw(requestParameters: SelectUserWalletOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling selectUserWalletOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/wallets/selection`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async selectUserWalletOptions(requestParameters: SelectUserWalletOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.selectUserWalletOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Check that session is valid
     */
    async sessionCheckRaw(requestParameters: SessionCheckRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling sessionCheck.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/session`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Check that session is valid
     */
    async sessionCheck(requestParameters: SessionCheckRequest, initOverrides?: RequestInit): Promise<void> {
        await this.sessionCheckRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async sessionCheckOptionsRaw(requestParameters: SessionCheckOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling sessionCheckOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/session`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async sessionCheckOptions(requestParameters: SessionCheckOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.sessionCheckOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async sessionKeyOptionsRaw(requestParameters: SessionKeyOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling sessionKeyOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/sessionKey`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async sessionKeyOptions(requestParameters: SessionKeyOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.sessionKeyOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async settingsOptionsRaw(requestParameters: SettingsOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling settingsOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/settings`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async settingsOptions(requestParameters: SettingsOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.settingsOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Verify an email and sign in user
     */
    async signInWithEmailVerificationRaw(requestParameters: SignInWithEmailVerificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling signInWithEmailVerification.');
        }

        if (requestParameters.emailVerificationVerifyRequest === null || requestParameters.emailVerificationVerifyRequest === undefined) {
            throw new runtime.RequiredError('emailVerificationVerifyRequest','Required parameter requestParameters.emailVerificationVerifyRequest was null or undefined when calling signInWithEmailVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/emailVerifications/signin`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailVerificationVerifyRequestToJSON(requestParameters.emailVerificationVerifyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Verify an email and sign in user
     */
    async signInWithEmailVerification(requestParameters: SignInWithEmailVerificationRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.signInWithEmailVerificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async signInWithEmailVerificationOptionsRaw(requestParameters: SignInWithEmailVerificationOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling signInWithEmailVerificationOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/emailVerifications/signin`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async signInWithEmailVerificationOptions(requestParameters: SignInWithEmailVerificationOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.signInWithEmailVerificationOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Verify an sms and sign in user
     */
    async signInWithSmsVerificationRaw(requestParameters: SignInWithSmsVerificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling signInWithSmsVerification.');
        }

        if (requestParameters.smsVerificationVerifyRequest === null || requestParameters.smsVerificationVerifyRequest === undefined) {
            throw new runtime.RequiredError('smsVerificationVerifyRequest','Required parameter requestParameters.smsVerificationVerifyRequest was null or undefined when calling signInWithSmsVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/smsVerifications/signin`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SmsVerificationVerifyRequestToJSON(requestParameters.smsVerificationVerifyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Verify an sms and sign in user
     */
    async signInWithSmsVerification(requestParameters: SignInWithSmsVerificationRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.signInWithSmsVerificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async signInWithSmsVerificationOptionsRaw(requestParameters: SignInWithSmsVerificationOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling signInWithSmsVerificationOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/smsVerifications/signin`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async signInWithSmsVerificationOptions(requestParameters: SignInWithSmsVerificationOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.signInWithSmsVerificationOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Simulate an EVM transaction
     */
    async simulateEVMTransactionRaw(requestParameters: SimulateEVMTransactionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimulateTransactionResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling simulateEVMTransaction.');
        }

        if (requestParameters.simulateEVMTransactionRequest === null || requestParameters.simulateEVMTransactionRequest === undefined) {
            throw new runtime.RequiredError('simulateEVMTransactionRequest','Required parameter requestParameters.simulateEVMTransactionRequest was null or undefined when calling simulateEVMTransaction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/evm/simulateTransaction`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SimulateEVMTransactionRequestToJSON(requestParameters.simulateEVMTransactionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimulateTransactionResponseFromJSON(jsonValue));
    }

    /**
     * Simulate an EVM transaction
     */
    async simulateEVMTransaction(requestParameters: SimulateEVMTransactionOperationRequest, initOverrides?: RequestInit): Promise<SimulateTransactionResponse> {
        const response = await this.simulateEVMTransactionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async simulateEVMTransactionOptionsRaw(requestParameters: SimulateEVMTransactionOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling simulateEVMTransactionOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/evm/simulateTransaction`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async simulateEVMTransactionOptions(requestParameters: SimulateEVMTransactionOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.simulateEVMTransactionOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Simulate a Solana transaction
     */
    async simulateSVMTransactionRaw(requestParameters: SimulateSVMTransactionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimulateTransactionResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling simulateSVMTransaction.');
        }

        if (requestParameters.simulateSVMTransactionRequest === null || requestParameters.simulateSVMTransactionRequest === undefined) {
            throw new runtime.RequiredError('simulateSVMTransactionRequest','Required parameter requestParameters.simulateSVMTransactionRequest was null or undefined when calling simulateSVMTransaction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/solana/simulateTransaction`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SimulateSVMTransactionRequestToJSON(requestParameters.simulateSVMTransactionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimulateTransactionResponseFromJSON(jsonValue));
    }

    /**
     * Simulate a Solana transaction
     */
    async simulateSVMTransaction(requestParameters: SimulateSVMTransactionOperationRequest, initOverrides?: RequestInit): Promise<SimulateTransactionResponse> {
        const response = await this.simulateSVMTransactionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async simulateSVMTransactionOptionsRaw(requestParameters: SimulateSVMTransactionOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling simulateSVMTransactionOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/solana/simulateTransaction`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async simulateSVMTransactionOptions(requestParameters: SimulateSVMTransactionOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.simulateSVMTransactionOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async telegramAuthOptionsRaw(requestParameters: TelegramAuthOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling telegramAuthOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/telegram/auth`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async telegramAuthOptions(requestParameters: TelegramAuthOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telegramAuthOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Telegram provider check auth
     */
    async telegramCheckAuthRaw(requestParameters: TelegramCheckAuthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling telegramCheckAuth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/checkTelegramAuth`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OauthResultRequestToJSON(requestParameters.oauthResultRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Telegram provider check auth
     */
    async telegramCheckAuth(requestParameters: TelegramCheckAuthRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telegramCheckAuthRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async telegramCheckAuthOptionsRaw(requestParameters: TelegramCheckAuthOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling telegramCheckAuthOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/checkTelegramAuth`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async telegramCheckAuthOptions(requestParameters: TelegramCheckAuthOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telegramCheckAuthOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Telegram provider SignIn endpoint
     */
    async telegramSignInRaw(requestParameters: TelegramSignInRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling telegramSignIn.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/telegram/signin`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OauthResultRequestToJSON(requestParameters.oauthResultRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Telegram provider SignIn endpoint
     */
    async telegramSignIn(requestParameters: TelegramSignInRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.telegramSignInRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async telegramSignInOptionsRaw(requestParameters: TelegramSignInOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling telegramSignInOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/telegram/signin`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async telegramSignInOptions(requestParameters: TelegramSignInOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telegramSignInOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Telegram provider Verify endpoint
     */
    async telegramVerifyRaw(requestParameters: TelegramVerifyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling telegramVerify.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/telegram/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OauthResultRequestToJSON(requestParameters.oauthResultRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Telegram provider Verify endpoint
     */
    async telegramVerify(requestParameters: TelegramVerifyRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.telegramVerifyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async telegramVerifyOptionsRaw(requestParameters: TelegramVerifyOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling telegramVerifyOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/telegram/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async telegramVerifyOptions(requestParameters: TelegramVerifyOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telegramVerifyOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Update a user\'s Turnkey recovery email
     */
    async updateEmbeddedWalletRecoveryEmailRaw(requestParameters: UpdateEmbeddedWalletRecoveryEmailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling updateEmbeddedWalletRecoveryEmail.');
        }

        if (requestParameters.updateRecoveryEmailRequest === null || requestParameters.updateRecoveryEmailRequest === undefined) {
            throw new runtime.RequiredError('updateRecoveryEmailRequest','Required parameter requestParameters.updateRecoveryEmailRequest was null or undefined when calling updateEmbeddedWalletRecoveryEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/recoveryEmail`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRecoveryEmailRequestToJSON(requestParameters.updateRecoveryEmailRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a user\'s Turnkey recovery email
     */
    async updateEmbeddedWalletRecoveryEmail(requestParameters: UpdateEmbeddedWalletRecoveryEmailRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateEmbeddedWalletRecoveryEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Update a passkey\'s info
     */
    async updatePasskeyRaw(requestParameters: UpdatePasskeyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserPasskey>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling updatePasskey.');
        }

        if (requestParameters.updateUserPasskeyRequest === null || requestParameters.updateUserPasskeyRequest === undefined) {
            throw new runtime.RequiredError('updateUserPasskeyRequest','Required parameter requestParameters.updateUserPasskeyRequest was null or undefined when calling updatePasskey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/passkeys`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserPasskeyRequestToJSON(requestParameters.updateUserPasskeyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPasskeyFromJSON(jsonValue));
    }

    /**
     * Update a passkey\'s info
     */
    async updatePasskey(requestParameters: UpdatePasskeyRequest, initOverrides?: RequestInit): Promise<UserPasskey> {
        const response = await this.updatePasskeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a user\'s fields
     */
    async updateSelfRaw(requestParameters: UpdateSelfRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpdateSelfResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling updateSelf.');
        }

        if (requestParameters.userFields === null || requestParameters.userFields === undefined) {
            throw new runtime.RequiredError('userFields','Required parameter requestParameters.userFields was null or undefined when calling updateSelf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserFieldsToJSON(requestParameters.userFields),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateSelfResponseFromJSON(jsonValue));
    }

    /**
     * Update a user\'s fields
     */
    async updateSelf(requestParameters: UpdateSelfRequest, initOverrides?: RequestInit): Promise<UpdateSelfResponse> {
        const response = await this.updateSelfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a device
     */
    async updateUserMfaDeviceRaw(requestParameters: UpdateUserMfaDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling updateUserMfaDevice.');
        }

        if (requestParameters.mfaDeviceId === null || requestParameters.mfaDeviceId === undefined) {
            throw new runtime.RequiredError('mfaDeviceId','Required parameter requestParameters.mfaDeviceId was null or undefined when calling updateUserMfaDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/users/mfa/{mfaDeviceId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"mfaDeviceId"}}`, encodeURIComponent(String(requestParameters.mfaDeviceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MFAUpdateDeviceRequestToJSON(requestParameters.mFAUpdateDeviceRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a device
     */
    async updateUserMfaDevice(requestParameters: UpdateUserMfaDeviceRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateUserMfaDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async userPasskeysOptionsRaw(requestParameters: UserPasskeysOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling userPasskeysOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/passkeys`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async userPasskeysOptions(requestParameters: UserPasskeysOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userPasskeysOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async usersOptionsRaw(requestParameters: UsersOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling usersOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async usersOptions(requestParameters: UsersOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.usersOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Verify payload and return JWT
     */
    async verifyRaw(requestParameters: VerifyOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verify.');
        }

        if (requestParameters.verifyRequest === null || requestParameters.verifyRequest === undefined) {
            throw new runtime.RequiredError('verifyRequest','Required parameter requestParameters.verifyRequest was null or undefined when calling verify.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sdk/{environmentId}/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyRequestToJSON(requestParameters.verifyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Verify payload and return JWT
     */
    async verify(requestParameters: VerifyOperationRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.verifyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verify email verification request
     */
    async verifyEmailVerificationRaw(requestParameters: VerifyEmailVerificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpdateSelfResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifyEmailVerification.');
        }

        if (requestParameters.emailVerificationVerifyRequest === null || requestParameters.emailVerificationVerifyRequest === undefined) {
            throw new runtime.RequiredError('emailVerificationVerifyRequest','Required parameter requestParameters.emailVerificationVerifyRequest was null or undefined when calling verifyEmailVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/emailVerifications/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailVerificationVerifyRequestToJSON(requestParameters.emailVerificationVerifyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateSelfResponseFromJSON(jsonValue));
    }

    /**
     * Verify email verification request
     */
    async verifyEmailVerification(requestParameters: VerifyEmailVerificationRequest, initOverrides?: RequestInit): Promise<UpdateSelfResponse> {
        const response = await this.verifyEmailVerificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async verifyEmailVerificationOptionsRaw(requestParameters: VerifyEmailVerificationOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifyEmailVerificationOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/emailVerifications/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async verifyEmailVerificationOptions(requestParameters: VerifyEmailVerificationOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.verifyEmailVerificationOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Link a wallet to a valid environment user, and return an updated JWT
     * Link wallet to user
     */
    async verifyLinkRaw(requestParameters: VerifyLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifyLink.');
        }

        if (requestParameters.verifyRequest === null || requestParameters.verifyRequest === undefined) {
            throw new runtime.RequiredError('verifyRequest','Required parameter requestParameters.verifyRequest was null or undefined when calling verifyLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/verify/link`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyRequestToJSON(requestParameters.verifyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Link a wallet to a valid environment user, and return an updated JWT
     * Link wallet to user
     */
    async verifyLink(requestParameters: VerifyLinkRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.verifyLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async verifyLinkOptionsRaw(requestParameters: VerifyLinkOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifyLinkOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/verify/link`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async verifyLinkOptions(requestParameters: VerifyLinkOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.verifyLinkOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Used to merge two users with user-chosen resolutions to conflicting data between the two users to be merged
     * Merge users with conflict resolutions
     */
    async verifyMergeUsersRaw(requestParameters: VerifyMergeUsersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifyMergeUsers.');
        }

        if (requestParameters.mergeUserConflictResolutions === null || requestParameters.mergeUserConflictResolutions === undefined) {
            throw new runtime.RequiredError('mergeUserConflictResolutions','Required parameter requestParameters.mergeUserConflictResolutions was null or undefined when calling verifyMergeUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/verify/merge`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergeUserConflictResolutionsToJSON(requestParameters.mergeUserConflictResolutions),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Used to merge two users with user-chosen resolutions to conflicting data between the two users to be merged
     * Merge users with conflict resolutions
     */
    async verifyMergeUsers(requestParameters: VerifyMergeUsersRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.verifyMergeUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async verifyMergeUsersOptionsRaw(requestParameters: VerifyMergeUsersOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifyMergeUsersOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/verify/merge`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async verifyMergeUsersOptions(requestParameters: VerifyMergeUsersOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.verifyMergeUsersOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async verifyOptionsRaw(requestParameters: VerifyOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifyOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async verifyOptions(requestParameters: VerifyOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.verifyOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async verifyPrefetchRaw(requestParameters: VerifyPrefetchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifyPrefetch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/prefetch`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async verifyPrefetch(requestParameters: VerifyPrefetchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.verifyPrefetchRaw(requestParameters, initOverrides);
    }

    /**
     * Verify sms verification request
     */
    async verifySmsVerificationRaw(requestParameters: VerifySmsVerificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpdateSelfResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifySmsVerification.');
        }

        if (requestParameters.smsVerificationVerifyRequest === null || requestParameters.smsVerificationVerifyRequest === undefined) {
            throw new runtime.RequiredError('smsVerificationVerifyRequest','Required parameter requestParameters.smsVerificationVerifyRequest was null or undefined when calling verifySmsVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/smsVerifications/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SmsVerificationVerifyRequestToJSON(requestParameters.smsVerificationVerifyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateSelfResponseFromJSON(jsonValue));
    }

    /**
     * Verify sms verification request
     */
    async verifySmsVerification(requestParameters: VerifySmsVerificationRequest, initOverrides?: RequestInit): Promise<UpdateSelfResponse> {
        const response = await this.verifySmsVerificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async verifySmsVerificationOptionsRaw(requestParameters: VerifySmsVerificationOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifySmsVerificationOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/smsVerifications/verify`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async verifySmsVerificationOptions(requestParameters: VerifySmsVerificationOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.verifySmsVerificationOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Used to link a wallet after user has confirmed transfer to the new account
     * Verify wallet transfer
     */
    async verifyTransferRaw(requestParameters: VerifyTransferRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifyTransfer.');
        }

        if (requestParameters.verifyRequest === null || requestParameters.verifyRequest === undefined) {
            throw new runtime.RequiredError('verifyRequest','Required parameter requestParameters.verifyRequest was null or undefined when calling verifyTransfer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/verify/transfer`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyRequestToJSON(requestParameters.verifyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Used to link a wallet after user has confirmed transfer to the new account
     * Verify wallet transfer
     */
    async verifyTransfer(requestParameters: VerifyTransferRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.verifyTransferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async verifyTransferOptionsRaw(requestParameters: VerifyTransferOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifyTransferOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/verify/transfer`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async verifyTransferOptions(requestParameters: VerifyTransferOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.verifyTransferOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Remove a link from to a valid environment user, and return an updated JWT
     * unlink wallet from user
     */
    async verifyUnlinkRaw(requestParameters: VerifyUnlinkOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VerifyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifyUnlink.');
        }

        if (requestParameters.verifyUnlinkRequest === null || requestParameters.verifyUnlinkRequest === undefined) {
            throw new runtime.RequiredError('verifyUnlinkRequest','Required parameter requestParameters.verifyUnlinkRequest was null or undefined when calling verifyUnlink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sdk/{environmentId}/verify/unlink`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyUnlinkRequestToJSON(requestParameters.verifyUnlinkRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyResponseFromJSON(jsonValue));
    }

    /**
     * Remove a link from to a valid environment user, and return an updated JWT
     * unlink wallet from user
     */
    async verifyUnlink(requestParameters: VerifyUnlinkOperationRequest, initOverrides?: RequestInit): Promise<VerifyResponse> {
        const response = await this.verifyUnlinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Options call for this endpoint
     */
    async verifyUnlinkOptionsRaw(requestParameters: VerifyUnlinkOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling verifyUnlinkOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/verify/unlink`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async verifyUnlinkOptions(requestParameters: VerifyUnlinkOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.verifyUnlinkOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async walletAccountOptionsRaw(requestParameters: WalletAccountOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling walletAccountOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/walletAccounts`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async walletAccountOptions(requestParameters: WalletAccountOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.walletAccountOptionsRaw(requestParameters, initOverrides);
    }

    /**
     * Options call for this endpoint
     */
    async walletAccountRequestOptionsRaw(requestParameters: WalletAccountRequestOptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling walletAccountRequestOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sdk/{environmentId}/users/embeddedWallets/walletAccounts/createRequest`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Options call for this endpoint
     */
    async walletAccountRequestOptions(requestParameters: WalletAccountRequestOptionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.walletAccountRequestOptionsRaw(requestParameters, initOverrides);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetAccountBalancesChainNameEnum {
    Evm = 'EVM',
    Sol = 'SOL'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAccountBalancesNetworkIdEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_10 = 10,
    NUMBER_137 = 137,
    NUMBER_42161 = 42161,
    NUMBER_8453 = 8453
}
/**
    * @export
    * @enum {string}
    */
export enum GetAccountBalancesOptionsChainNameEnum {
    Evm = 'EVM',
    Sol = 'SOL'
}
