import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Alert } from '@dynamic-labs/northstar';

export const EnsSubdomainsDrawer: FC = () => {
  const { t } = useTranslation();

  return (
    <Alert
      title={t(
        'v2.page.log_in_methods.ens_subdomains.drawer.no_addresses_alert.title',
      )}
      description={t(
        'v2.page.log_in_methods.ens_subdomains.drawer.no_addresses_alert.description',
      )}
      variant='warning'
    />
  );
};
