export type ChainName = 'evm' | 'solana' | 'cosmos' | 'starknet' | 'eclipse';
type DefaultMapType = Record<string, string[]>;

export const DefaultRpcMap: Readonly<Record<ChainName, DefaultMapType>> = {
  cosmos: {
    401: ['https://rpc-cosmoshub.keplr.app'],
    402: ['https://rpc-axelar.keplr.app'],
    403: ['https://rpc-osmosis.keplr.app'],
    404: ['https://rpc.wallet.pacific-1.sei.io'],
  },
  eclipse: {
    1: ['https://mainnetbeta-rpc.eclipse.xyz'],
  },
  evm: {
    1: ['https://rpc.ankr.com/eth'],
    10: ['https://mainnet.optimism.io'],
    100: ['https://rpc.gnosischain.com'],
    111: ['https://testnet.rpc.gobob.xyz'],
    11155111: ['https://rpc.sepolia.org'],
    11155420: ['https://sepolia.optimism.io'],
    11297108109: [
      'https://palm-mainnet.infura.io/v3/3a961d6501e54add9a41aa53f15de99b',
    ],
    1284: ['https://rpc.api.moonbeam.network'],
    1287: ['https://rpc.api.moonbase.moonbeam.network'],
    1313161554: ['https://mainnet.aurora.dev'],
    1328: ['https://evm-rpc-testnet.sei-apis.com'],
    1329: ['https://evm-rpc.sei-apis.com	'],
    137: ['https://polygon-rpc.com'],
    13746: ['https://rpc-game7-testnet-0ilneybprf.t.conduit.xyz'],
    1513: ['https://rpc.partner.testnet.storyprotocol.net/'],
    1516: ['https://odyssey.storyrpc.io'],
    1663: ['https://gobi-rpc.horizenlabs.io/ethv1'],
    17000: ['https://ethereum-holesky-rpc.publicnode.com/'],
    183: ['https://mainnet.ethernitychain.io'],
    1890: ['https://replicator.phoenix.lightlink.io/rpc/v1'],
    195: ['https://testrpc.xlayer.tech'],
    196: ['https://rpc.xlayer.tech'],
    204: ['https://opbnb-mainnet-rpc.bnbchain.org'],
    23294: ['https://sapphire.oasis.io'],
    233: ['https://testnet.ethernitychain.io'],
    25: ['https://evm.cronos.org'],
    250: ['https://rpc.ankr.com/fantom'],
    280: ['https://testnet.era.zksync.dev'],
    2810: ['https://rpc-quicknode-holesky.morphl2.io/'],
    300: ['https://sepolia.era.zksync.dev'],
    314: ['https://api.node.glif.io'],
    314159: ['https://filecoin-calibration.chainup.net/rpc/v1'],
    324: ['https://mainnet.era.zksync.io'],
    33111: ['https://curtis.rpc.caldera.xyz/http'],
    33139: ['https://apechain.calderachain.xyz/http'],
    338: ['https://evm-t3.cronos.org'],
    34443: ['https://mainnet.mode.network'],
    420: ['https://goerli.optimism.io'],
    42161: ['https://arb1.arbitrum.io/rpc'],
    421613: ['https://goerli-rollup.arbitrum.io/rpc'],
    421614: ['https://sepolia-rollup.arbitrum.io/rpc'],
    42220: ['https://forno.celo.org'],
    43114: ['https://api.avax.network/ext/bc/C/rpc'],
    48899: ['https://zircuit1.p2pify.com'],
    5: ['https://rpc.ankr.com/eth_goerli'],
    5000: ['https://rpc.mantle.xyz'],
    5003: ['https://rpc.sepolia.mantle.xyz'],
    534351: ['https://sepolia-rpc.scroll.io'],
    534352: ['https://rpc.scroll.io'],
    545: ['https://testnet.evm.nodes.onflow.org'],
    56: ['https://bsc-dataseed1.binance.org'],
    5611: ['https://opbnb-testnet-rpc.bnbchain.org'],
    59141: ['https://rpc.sepolia.linea.build'],
    59144: ['https://rpc.linea.build'],
    60808: ['https://bob-mainnet.public.blastapi.io'],
    666666666: ['https://rpc.degen.tips'],
    7332: ['https://eon-rpc.horizenlabs.io/ethv1'],
    747: ['https://mainnet.evm.nodes.onflow.org'],
    7668: ['https://root.rootnet.live/archive'],
    7777777: ['https://rpc.zora.energy'],
    80001: ['https://rpc.ankr.com/polygon_mumbai'],
    80002: ['https://rpc-amoy.polygon.technology'],
    80084: ['https://bartio.rpc.berachain.com'],
    80085: ['https://artio.rpc.berachain.com'],
    8082: ['https://sphinx.shardeum.org/'],
    81457: ['https://rpc.blast.io/'],
    8453: ['https://mainnet.base.org'],
    84531: ['https://goerli.base.org'],
    84532: ['https://sepolia.base.org'],
    88888: ['https://rpc.chiliz.com'],
    911867: ['https://odyssey.ithaca.xyz'],
    919: ['https://sepolia.mode.network'],
    999999999: ['https://sepolia.rpc.zora.energy'],
  },

  solana: {
    101: ['https://api.mainnet-beta.solana.com'],
  },
  starknet: {
    410: ['https://starknet-mainnet.public.blastapi.io'],
    411: ['https://starknet-testnet.public.blastapi.io'],
    412: ['https://starknet-sepolia.public.blastapi.io'],
  },
};
